import {AnyAction} from 'redux';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {GameActionTypes} from './types';
import {
  gameFetchManySuccess,
  gameFetchManyError,
  gameFetchSuccess,
  gameFetchError,
  gameUpdateSuccess,
  gameUpdateError,
} from './actions';
import { getErrorFromLua } from '../../utils/error'

const default_key = process.env.REACT_APP_DEFAULTKEY || 'defaultkey_http_123'

function* handleFetchMany({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_game', null, default_key, data);
    if(res.error) {
      yield put(gameFetchManyError(res.error));
    } else {
      yield put(gameFetchManySuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(gameFetchManyError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(gameFetchManyError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(gameFetchManyError('An unknown error occured.'));
    }
  }
}

function* handleFetch({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_game', null, default_key, data);
    if(res.error) {
      yield put(gameFetchError(res.error));
    } else {
      yield put(gameFetchSuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (err.status == 404) {
      window.location.href = '/games';
    } else if(err.json) {
      const json = yield err.json();
      yield put(gameFetchError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(gameFetchError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(gameFetchError('An unknown error occured.'));
    }
  }
}

function* handleUpdate({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('set_game', null, default_key, data);
    if(res.error) {
      yield put(gameUpdateError(res.error));
    } else {
      yield put(gameUpdateError(''));
      yield put(gameUpdateSuccess());
      yield handleFetch({type: '@@game/FETCH_REQUEST', payload: data});
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(gameUpdateError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(gameUpdateError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(gameUpdateError('An unknown error occured.'));
    }
  }
}

function* watchFetchMany() {
  yield takeEvery(GameActionTypes.FETCH_MANY_REQUEST, handleFetchMany);
}

function* watchFetch() {
  yield takeEvery(GameActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchUpdate() {
  yield takeEvery(GameActionTypes.UPDATE_REQUEST, handleUpdate);
}

export function* gameSaga()
{
  yield all([
    fork(watchFetchMany),
    fork(watchFetch),
    fork(watchUpdate),
  ]);
}
