import {action} from 'typesafe-actions';
import {
  DemoActionTypes,
  CreateUserRequest,
  DemoTopupRequest,
  DemoActionRequest,
  DemoConsumeGemsRequest,
  DemoGetConfigRequest,
  DemoSetConfigRequest,
  DemoExchangeGiftRequest,
} from './types';

export const createUserRequest = (data: CreateUserRequest) => action(
  DemoActionTypes.CREATE_USER_REQUEST,
  data
);
export const createUserSuccess = (data: CreateUserRequest) => action(
  DemoActionTypes.CREATE_USER_SUCCESS,
  data
);
export const createUserError = (message: string) => action(
  DemoActionTypes.CREATE_USER_ERROR,
  message
);


export const demoTopupRequest = (data: DemoTopupRequest) => action(
  DemoActionTypes.DEMO_TOPUP_REQUEST,
  data
);
export const demoTopupSuccess = (data: DemoTopupRequest) => action(
  DemoActionTypes.DEMO_TOPUP_SUCCESS,
  data
);
export const demoTopupError = (message: string) => action(
  DemoActionTypes.DEMO_TOPUP_ERROR,
  message
);


export const demoActionRequest = (data: DemoActionRequest) => action(
  DemoActionTypes.DEMO_ACTION_REQUEST,
  data
);
export const demoActionSuccess = (data: DemoActionRequest) => action(
  DemoActionTypes.DEMO_ACTION_SUCCESS,
  data
);
export const demoActionError = (message: string) => action(
  DemoActionTypes.DEMO_ACTION_ERROR,
  message
);


export const demoConsumeGemsRequest = (data: DemoConsumeGemsRequest) => action(
  DemoActionTypes.DEMO_CONSUME_GEMS_REQUEST,
  data
);
export const demoConsumeGemsSuccess = (data: DemoConsumeGemsRequest) => action(
  DemoActionTypes.DEMO_CONSUME_GEMS_SUCCESS,
  data
);
export const demoConsumeGemsError = (message: string) => action(
  DemoActionTypes.DEMO_CONSUME_GEMS_ERROR,
  message
);


export const demoGetConfigRequest = (data: DemoGetConfigRequest) => action(
  DemoActionTypes.DEMO_GET_CONFIG_REQUEST,
  data
);
export const demoGetConfigSuccess = (data: DemoGetConfigRequest) => action(
  DemoActionTypes.DEMO_GET_CONFIG_SUCCESS,
  data
);
export const demoGetConfigError = (message: string) => action(
  DemoActionTypes.DEMO_GET_CONFIG_ERROR,
  message
);


export const demoSetConfigRequest = (data: DemoSetConfigRequest) => action(
  DemoActionTypes.DEMO_SET_CONFIG_REQUEST,
  data
);
export const demoSetConfigSuccess = (data: DemoSetConfigRequest) => action(
  DemoActionTypes.DEMO_SET_CONFIG_SUCCESS,
  data
);
export const demoSetConfigError = (message: string) => action(
  DemoActionTypes.DEMO_SET_CONFIG_ERROR,
  message
);


export const demoExchangeGiftRequest = (data: DemoExchangeGiftRequest) => action(
  DemoActionTypes.DEMO_EXCHANGE_GIFT_REQUEST,
  data
);
export const demoExchangeGiftSuccess = (data: DemoExchangeGiftRequest) => action(
  DemoActionTypes.DEMO_EXCHANGE_GIFT_SUCCESS,
  data
);
export const demoExchangeGiftError = (message: string) => action(
  DemoActionTypes.DEMO_EXCHANGE_GIFT_ERROR,
  message
);
