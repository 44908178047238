import React from 'react';
import ReactDOM from 'react-dom';

import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {createRootReducer, rootSaga} from './store';

import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {NakamaApi} from './api.gen';
import {Client} from '@heroiclabs/nakama-js';

import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faBan,
  faFile,
  faFileCsv,
  faFileExport,
  faLink,
  faSignOutAlt,
  faTrash,
  faUsersCog
} from '@fortawesome/free-solid-svg-icons';

import Index from './routes/index';
import Login from './routes/login';
import Status from './routes/status';
import Configuration from './routes/configuration';
import Users from './routes/users';
import UsersDetails from './routes/users/details';
import Storage from './routes/storage';
import StorageDetails from './routes/storage/details';
import Demo from './routes/demo';
import Gifts from './routes/gifts';
import GiftsDetails from './routes/gifts/details';
import WheelGifts from './routes/wheel_gifts';
import WheelGiftsDetails from './routes/wheel_gifts/details';
import Games from './routes/games';
import GamesDetails from './routes/games/details';
import UserGifts from './routes/user_gifts';
import GiftConfig from './routes/wheel_gifts/config';

import 'rbx/index.css';
import './css/index.css';

declare global {
  interface Window {
    nakama_api: any;
    nakama_client: any;
  }
}

library.add(
  faAngleDown,
  faBan,
  faFile,
  faFileCsv,
  faFileExport,
  faLink,
  faSignOutAlt,
  faTrash,
  faUsersCog
);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  createRootReducer(),
  {},
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

const state = store.getState();
window.nakama_api = NakamaApi({
  basePath: process.env.REACT_APP_BASE_PATH || window.location.origin,
  bearerToken: (
    state &&
    state.login &&
    state.login.data &&
    state.login.data.token
  ) || localStorage.getItem('token') || '',
  timeoutMs: 5000
});

//window.nakama_client = new Client('defaultkey', 'itme.mana.vn', '7350');
//window.nakama_client = new Client('defaultkey', 'localhost', '7350');
const nakama_client_key = process.env.REACT_APP_NK_CLIENT_KEY || 'defaultkey'
const nakama_host = process.env.REACT_APP_NK_HOST || 'localhost'
const nakama_port = process.env.REACT_APP_NK_PORT || '7350'
const nakama_ssl = Boolean(Number(process.env.REACT_APP_NK_SSL)) || false
window.nakama_client = new Client(nakama_client_key, nakama_host, nakama_port, nakama_ssl);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/storage/:collection/:key/:user_id" component={StorageDetails}/>
        <Route path="/storage" component={Storage}/>
        <Route path="/users/:id" component={UsersDetails}/>
        <Route path="/users" component={Users}/>
        <Route path="/configuration" component={Configuration}/>
        <Route path="/status" component={Status}/>
        <Route path="/login" component={Login}/>
        <Route path="/demo/:tab" component={Demo}/>
        <Route path="/demo" component={Demo}/>
        <Route path="/gifts/:id" component={GiftsDetails}/>
        <Route path="/gifts" component={Gifts}/>
        <Route path="/wheel_gifts/config" component={GiftConfig}/>
        <Route path="/wheel_gifts/:id" component={WheelGiftsDetails}/>
        <Route path="/wheel_gifts" component={WheelGifts}/>
        <Route path="/games/:id" component={GamesDetails}/>
        <Route path="/games" component={Games}/>
        <Route path="/user_gifts" component={UserGifts}/>
        <Route path="/" component={Index}/>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
