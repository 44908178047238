import {action} from 'typesafe-actions';
import {
  UserGiftActionTypes,
  UserGiftObjectRequest,
  UserGiftObject,
  UserGiftsObjectRequest,
  UserGiftsObject,
} from './types';

export const userGiftSelectRequest = (data: any) => action(
  UserGiftActionTypes.SELECT,
  data
);

export const userGiftFetchManyRequest = (data: UserGiftsObjectRequest) => action(
  UserGiftActionTypes.FETCH_MANY_REQUEST,
  data
);
export const userGiftFetchManySuccess = (data: UserGiftsObject) => action(
  UserGiftActionTypes.FETCH_MANY_SUCCESS,
  data
);
export const userGiftFetchManyError = (message: string) => action(
  UserGiftActionTypes.FETCH_MANY_ERROR,
  message
);

export const userGiftFetchRequest = (data: UserGiftObjectRequest) => action(
  UserGiftActionTypes.FETCH_REQUEST,
  data
);
export const userGiftFetchSuccess = (data: UserGiftObject) => action(
  UserGiftActionTypes.FETCH_SUCCESS,
  data
);
export const userGiftFetchError = (message: string) => action(
  UserGiftActionTypes.FETCH_ERROR,
  message
);

export const userGiftUpdateRequest = (data: UserGiftObject) => action(
  UserGiftActionTypes.UPDATE_REQUEST,
  data
);
export const userGiftUpdateSuccess = () => action(
  UserGiftActionTypes.UPDATE_SUCCESS
);
export const userGiftUpdateError = (message: string) => action(
  UserGiftActionTypes.UPDATE_ERROR,
  message
);
