import {AnyAction} from 'redux';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {WheelGiftActionTypes} from './types';
import {
  wheelGiftFetchManySuccess,
  wheelGiftFetchManyError,
  wheelGiftFetchSuccess,
  wheelGiftFetchError,
  wheelGiftUpdateSuccess,
  wheelGiftUpdateError,
} from './actions';
import { getErrorFromLua } from '../../utils/error'

export const default_key = process.env.REACT_APP_DEFAULTKEY || 'defaultkey_http_123'

function* handleFetchMany({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_wheel_gift', null, default_key, data);
    if(res.error) {
      yield put(wheelGiftFetchManyError(res.error));
    } else {
      yield put(wheelGiftFetchManySuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(wheelGiftFetchManyError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(wheelGiftFetchManyError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(wheelGiftFetchManyError('An unknown error occured.'));
    }
  }
}

function* handleFetch({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_wheel_gift', null, default_key, data);
    if(res.error) {
      yield put(wheelGiftFetchError(res.error));
    } else {
      yield put(wheelGiftFetchSuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (err.status == 404) {
      window.location.href = '/wheel_gifts';
    } else if(err.json) {
      const json = yield err.json();
      yield put(wheelGiftFetchError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(wheelGiftFetchError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(wheelGiftFetchError('An unknown error occured.'));
    }
  }
}

function* handleUpdate({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('set_wheel_gift', null, default_key, data);
    if(res.error) {
      yield put(wheelGiftUpdateError(res.error));
    } else {
      yield put(wheelGiftUpdateError(''));
      yield put(wheelGiftUpdateSuccess());
      yield handleFetch({type: '@@wheel_gift/FETCH_REQUEST', payload: data});
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(wheelGiftUpdateError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(wheelGiftUpdateError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(wheelGiftUpdateError('An unknown error occured.'));
    }
  }
}

function* watchFetchMany() {
  yield takeEvery(WheelGiftActionTypes.FETCH_MANY_REQUEST, handleFetchMany);
}

function* watchFetch() {
  yield takeEvery(WheelGiftActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchUpdate() {
  yield takeEvery(WheelGiftActionTypes.UPDATE_REQUEST, handleUpdate);
}

export function* wheelGiftSaga()
{
  yield all([
    fork(watchFetchMany),
    fork(watchFetch),
    fork(watchUpdate),
  ]);
}
