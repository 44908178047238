import {AnyAction} from 'redux';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {DemoActionTypes} from './types';
import {
  createUserRequest,
  createUserSuccess,
  createUserError,
  demoTopupRequest,
  demoTopupSuccess,
  demoTopupError,
  demoActionRequest,
  demoActionSuccess,
  demoActionError,
  demoConsumeGemsRequest,
  demoConsumeGemsSuccess,
  demoConsumeGemsError,
  demoGetConfigRequest,
  demoGetConfigSuccess,
  demoGetConfigError,
  demoSetConfigRequest,
  demoSetConfigSuccess,
  demoSetConfigError,
  demoExchangeGiftRequest,
  demoExchangeGiftSuccess,
  demoExchangeGiftError,
} from './actions';
import { giftFetchManyRequest } from '../gifts/actions'
import { getErrorFromLua } from '../../utils/error'

const default_key = process.env.REACT_APP_DEFAULTKEY || 'defaultkey_http_123'

function* handleCreateUser({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.authenticateCustom(data);
    if (res.error) {
      yield put(createUserError(res.error));
    } else {
      yield put(createUserSuccess(res));
    }
  } catch(err) {
    console.log('error', err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(createUserError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(createUserError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(createUserError('An unknown error occured.'));
    }
  }
}

function* handleTopup({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('topup', null, default_key, data);
    if (res.error) {
      yield put(demoTopupError(res.error));
    } else {
      yield put(demoTopupSuccess(res.payload));
    }
  } catch(err) {
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(demoTopupError(json.error || JSON.stringify(json)));
    } else if(err instanceof Error) {
      yield put(demoTopupError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(demoTopupError('An unknown error occured.'));
    }
  }
}

function* handleAction({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('action', null, default_key, data);
    if (res.error) {
      yield put(demoActionError(res.error));
    } else {
      yield put(demoActionSuccess(res.payload));
    }
  } catch(err) {
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(demoActionError(json.error || JSON.stringify(json)));
    } else if(err instanceof Error) {
      yield put(demoActionError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(demoActionError('An unknown error occured.'));
    }
  }
}

function* handleConsumeGems({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('consume_gems', null, default_key, data);
    if (res.error) {
      yield put(demoConsumeGemsError(res.error));
    } else {
      yield put(demoConsumeGemsSuccess(res.payload));
    }
  } catch(err) {
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(demoConsumeGemsError(json.error || JSON.stringify(json)));
    } else if(err instanceof Error) {
      yield put(demoConsumeGemsError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(demoConsumeGemsError('An unknown error occured.'));
    }
  }
}

function* handleGetConfig({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_config', null, default_key, data);
    if (res.error) {
      yield put(demoGetConfigError(res.error));
    } else {
      yield put(demoGetConfigSuccess(res.payload.config));
    }
  } catch(err) {
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(demoGetConfigError(json.error || JSON.stringify(json)));
    } else if(err instanceof Error) {
      yield put(demoGetConfigError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(demoGetConfigError('An unknown error occured.'));
    }
  }
}

function* handleSetConfig({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('set_config', null, default_key, data);
    if (res.error) {
      yield put(demoSetConfigError(res.error));
    } else {
      yield put(demoSetConfigSuccess(res.payload));
    }
  } catch(err) {
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(demoSetConfigError(json.error || JSON.stringify(json)));
    } else if(err instanceof Error) {
      yield put(demoSetConfigError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(demoSetConfigError('An unknown error occured.'));
    }
  }
}

function* handleExchangeGift({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('exchange_gift', null, default_key, data);
    if (res.error) {
      yield put(demoExchangeGiftError(res.error));
    } else {
      yield put(demoExchangeGiftSuccess(res.payload));
      yield put(giftFetchManyRequest({}))
    }
  } catch(err) {
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      var a = err;
      const json = yield err.json();
      yield put(demoExchangeGiftError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(demoExchangeGiftError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(demoExchangeGiftError('An unknown error occured.'));
    }
  }
}

function* watchCreateUser() {
  yield takeEvery(DemoActionTypes.CREATE_USER_REQUEST, handleCreateUser);
}

function* watchDemoTopup() {
  yield takeEvery(DemoActionTypes.DEMO_TOPUP_REQUEST, handleTopup);
}

function* watchDemoAction() {
  yield takeEvery(DemoActionTypes.DEMO_ACTION_REQUEST, handleAction);
}

function* watchDemoConsumeGems() {
  yield takeEvery(DemoActionTypes.DEMO_CONSUME_GEMS_REQUEST, handleConsumeGems);
}

function* watchDemoGetConfig() {
  yield takeEvery(DemoActionTypes.DEMO_GET_CONFIG_REQUEST, handleGetConfig);
}

function* watchDemoSetConfig() {
  yield takeEvery(DemoActionTypes.DEMO_SET_CONFIG_REQUEST, handleSetConfig);
}

function* watchDemoExchangeGift() {
  yield takeEvery(DemoActionTypes.DEMO_EXCHANGE_GIFT_REQUEST, handleExchangeGift);
}

export function* demoSaga() {
  yield all([
    fork(watchCreateUser),
    fork(watchDemoTopup),
    fork(watchDemoAction),
    fork(watchDemoConsumeGems),
    fork(watchDemoGetConfig),
    fork(watchDemoSetConfig),
    fork(watchDemoExchangeGift),
  ]);
}
