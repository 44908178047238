import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import queryString from 'query-string';

import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as giftActions from '../../store/gifts/actions';
import {GiftObject, GiftObjectRequest, GiftsObject, GiftsObjectRequest} from '../../store/gifts/types';

import {Button, Column, Control, Field, Generic, Icon, Input, Level, Section, Table, Title} from 'rbx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';

interface PropsFromState {
  loading: boolean,
  errors: string | undefined,
  data: GiftsObject,
  selected?: GiftObject,
}

interface PropsFromDispatch {
  fetchManyRequest: typeof giftActions.giftFetchManyRequest,
  selectRequest: typeof giftActions.giftSelectRequest,
}

type Props = RouteComponentProps & PropsFromState & PropsFromDispatch & ConnectedReduxProps;

type State = {
  filter: string,
};

class Gifts extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {filter: ''};
  }

  public componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.filter) {
      (document.getElementById('filter') as HTMLInputElement).value = query.filter as string;
    }
    this.setState({
      filter: query.filter as string || '',
    });
    this.props.fetchManyRequest({
      filter: query.filter as string || '',
    });
  }

  public all() {
    this.props.fetchManyRequest({
      filter: this.state.filter,
    });
  }

  public filter() {
    const {history} = this.props;
    const filter = (document.getElementById('filter') as HTMLInputElement).value;
    history.push(`/gifts?filter=${filter}`);
    this.setState({filter});
    this.props.fetchManyRequest({filter});
  }

  public details(id: string) {
    const {history} = this.props;
    history.push(`/gifts/${id}`);
  }

  public add() {
    const {history} = this.props;
    history.push(`/gifts/add`);
  }

  public select_gift(gift: GiftObject | undefined, event: React.FormEvent<Element>) {
    event.stopPropagation();
    event.preventDefault();
    const { selectRequest } = this.props
    selectRequest(gift)
    if (gift) {
      const { history, location } = this.props;
      const query = queryString.parse(location.search) as any;
      if (query.back) history.push(query.back);
    }
  }

  public render() {
    const {data, selected} = this.props;
    const selected_id = selected && selected.id;

    return <Generic id="gifts">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="gifts"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Title subtitle size={5}>
                    ~<strong>{data.total_count}</strong> items
                  </Title>
                </Level.Item>

                <Level.Item>
                  <Field kind="addons">
                    <Control>
                      <Input id="filter" type="text" placeholder="Find a gift"/>
                    </Control>
                    <Control>
                      <Button onClick={this.filter.bind(this)}>Lookup</Button>
                    </Control>
                  </Field>
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                  <Control>
                    <Button onClick={this.add.bind(this)}>Create</Button>
                  </Control>
                </Level.Item>
              </Level.Item>
            </Level>

            <Table fullwidth striped hoverable>
              <Table.Head>
                <Table.Row>
                  <Table.Heading style={{width: '18%'}}>ID</Table.Heading>
                  <Table.Heading style={{width: '17.5%'}}>Name</Table.Heading>
                  <Table.Heading style={{width: '17.5%'}}>Description</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Image</Table.Heading>
                  <Table.Heading style={{width: '8'}}>Gems</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Coins</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Quantity</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Voucher</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Voucher</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Select</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {
                  data.gifts && data.gifts.length && data.gifts.map((gift, key) =>
                    <Table.Row
                      key={`cell_${key}`}
                      selected={selected_id === gift.id}
                      onClick={this.details.bind(this, gift.id)}
                    >
                      <Table.Cell>{gift.id}</Table.Cell>
                      <Table.Cell>{gift.name}</Table.Cell>
                      <Table.Cell>{gift.description}</Table.Cell>
                      <Table.Cell>{gift.image_url && <img src={gift.image_url} width="80" />}</Table.Cell>
                      <Table.Cell>{gift.gems}</Table.Cell>
                      <Table.Cell>{gift.coins}</Table.Cell>
                      <Table.Cell>{gift.voucher ? 'x' : ''}</Table.Cell>
                      <Table.Cell>{gift.quantity}</Table.Cell>
                      <Table.Cell>
                        &nbsp;
                        {
                          selected_id !== gift.id
                            ? <Button size="small" onClick={this.select_gift.bind(this, gift)}>Select</Button>
                            : <Button size="small" onClick={this.select_gift.bind(this, undefined)}>Unselect</Button>
                        }
                    </Table.Cell>
                    </Table.Row>
                  )
                }
              </Table.Body>
            </Table>
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({gift}: ApplicationState) => ({
  loading: gift.loading,
  errors: gift.errors,
  data: gift.data,
  selected: gift.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchManyRequest: (data: GiftsObjectRequest) => dispatch(
    giftActions.giftFetchManyRequest(data)
  ),
  selectRequest: (data: GiftObject) => dispatch(
    giftActions.giftSelectRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gifts);
