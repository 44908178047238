import {combineReducers, Dispatch, Action, AnyAction} from 'redux';
import {all, fork} from 'redux-saga/effects';

import {loginSaga} from './login/sagas';
import {loginReducer} from './login/reducer';
import {LoginState} from './login/types';

import {configurationSaga} from './configuration/sagas';
import {configurationReducer} from './configuration/reducer';
import {ConfigurationState} from './configuration/types';

import {storageSaga} from './storage/sagas';
import {storageReducer, storagesReducer} from './storage/reducer';
import {StorageState, StoragesState} from './storage/types';

import {userSaga} from './users/sagas';
import {userReducer, usersReducer} from './users/reducer';
import {UserState, UsersState, UserObject} from './users/types';

import {statusSaga} from './status/sagas';
import {statusReducer} from './status/reducer';
import {StatusState} from './status/types';

import {demoSaga} from './demo/sagas';
import {demoReducer} from './demo/reducer';
import {DemoState} from './demo/types';

import {giftSaga} from './gifts/sagas';
import {giftsReducer} from './gifts/reducer';
import {giftReducer} from './gifts/reducer';
import {GiftState, GiftsState, GiftObject} from './gifts/types';

import {wheelGiftSaga} from './wheel_gifts/sagas';
import {wheelGiftsReducer} from './wheel_gifts/reducer';
import {wheelGiftReducer} from './wheel_gifts/reducer';
import {WheelGiftState, WheelGiftsState, WheelGiftObject} from './wheel_gifts/types';

import {gameSaga} from './games/sagas';
import {gamesReducer} from './games/reducer';
import {gameReducer} from './games/reducer';
import {GameState, GamesState, GameObject} from './games/types';

import {userGiftSaga} from './user_gifts/sagas';
import {userGiftsReducer} from './user_gifts/reducer';
import {userGiftReducer} from './user_gifts/reducer';
import {UserGiftState, UserGiftsState, UserGiftObject} from './user_gifts/types';

export interface ConnectedReduxProps<A extends Action = AnyAction>
{
  dispatch: Dispatch<A>
};

export interface ApplicationState
{
  login: LoginState,
  configuration: ConfigurationState,
  storage: StoragesState,
  storage_details: StorageState,
  user: UsersState,
  user_details: UserState,
  status: StatusState,
  demo: DemoState,
  gift: GiftsState,
  gift_details: GiftState,
  wheel_gift: WheelGiftsState,
  wheel_gift_details: WheelGiftState,
  game: GamesState,
  game_details: GameState,
  selected_user?: UserObject,
  user_gift: UserGiftsState,
};

export const createRootReducer = () =>
  combineReducers({
    login: loginReducer,
    configuration: configurationReducer,
    storage: storagesReducer,
    storage_details: storageReducer,
    user: usersReducer,
    user_details: userReducer,
    status: statusReducer,
    demo: demoReducer,
    gift: giftsReducer,
    gift_details: giftReducer,
    wheel_gift: wheelGiftsReducer,
    wheel_gift_details: wheelGiftReducer,
    game: gamesReducer,
    game_details: gameReducer,
    user_gift: userGiftsReducer,
  });

export function* rootSaga()
{
  yield all([
    fork(loginSaga),
    fork(configurationSaga),
    fork(storageSaga),
    fork(userSaga),
    fork(statusSaga),
    fork(demoSaga),
    fork(giftSaga),
    fork(wheelGiftSaga),
    fork(gameSaga),
    fork(userGiftSaga),
  ]);
};
