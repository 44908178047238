import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router'; import {Link} from 'react-router-dom'; 
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as giftActions from '../../store/gifts/actions';
import {
  GiftObject,
  GiftObjectRequest
} from '../../store/gifts/types';

import {
  Breadcrumb,
  Button,
  Column,
  Control,
  Field,
  Generic,
  Icon,
  Input,
  Label,
  Level,
  Notification,
  Section,
  Tab,
  Table,
  Tag,
  Textarea
} from 'rbx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';

interface GiftsDetails {
  [key: string]: any;
}

interface PropsFromState {
  loading: boolean,
  errors: string | undefined,
  updated: boolean,
  data: GiftObject,
}

interface PropsFromDispatch {
  fetchRequest: typeof giftActions.giftFetchRequest,
  updateRequest: typeof giftActions.giftUpdateRequest,
}

type Props = RouteComponentProps & PropsFromState & PropsFromDispatch & ConnectedReduxProps;

interface State {
}

class GiftsDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    const {match, history} = this.props;
    const params = match.params as any
    if (params.id != 'add') {
      this.props.fetchRequest(match.params);
    }
  }

  public key(prefix: string) {
    const {data} = this.props;
    return `${prefix}_${data.id}`;
  }

  public update_record(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const payload = {
      id: data.get('id') as string,
      name: data.get('name') as string,
      description: data.get('description') as string,
      image_url: data.get('image_url') as string,
      gems: Number(data.get('gems') as string),
      coins: Number(data.get('coins') as string),
      quantity: Number(data.get('quantity') as string),
    };
    this.props.updateRequest(payload);
  }
  
  public render_record() {
    const {match, data, updated, errors} = this.props;
    
    return <form onSubmit={this.update_record.bind(this)}>
      <Column.Group>
        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>ID</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('id')}
                    static
                    type="text"
                    name="id"
                    defaultValue={data.id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Name</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('name')}
                    type="text"
                    placeholder="(empty)"
                    name="name"
                    maxLength="128"
                    defaultValue={data.name}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Description</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('description')}
                    type="text"
                    placeholder="(empty)"
                    name="description"
                    rows={6}
                    defaultValue={data.description}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Image url</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('image_url')}
                    placeholder="(empty)"
                    rows={3}
                    maxLength="1024"
                    name="image_url"
                    defaultValue={data.image_url}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Gems</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('gems')}
                    type="number"
                    name="gems"
                    defaultValue={data.gems}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Coins</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('coins')}
                    type="number"
                    name="coins"
                    defaultValue={data.coins}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Quantity</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('quantity')}
                    type="number"
                    name="quantity"
                    defaultValue={data.quantity}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Voucher</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('voucher')}
                    name="voucher"
                    defaultValue={data.voucher}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field kind="group" align="right">
            {
              updated ?
                <Notification color="success">Successfully updated user profile record.</Notification> :
                null
            }
            {
              errors ?
                <Notification color="danger">{errors}</Notification> :
                null
            }
            &nbsp;
            <Control>
              <Button color="info">Update</Button>
            </Control>
          </Field>
        </Column>

        <Column size={6}>
        </Column>
      </Column.Group>
    </form>;

  }

  public render() {
    const {match} = this.props;
    return <Generic id="gifts_details">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="gifts"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Breadcrumb>
                    <Breadcrumb.Item as="span"><Link to="/gifts">Gifts</Link></Breadcrumb.Item>
                  </Breadcrumb>
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                </Level.Item>
              </Level.Item>
            </Level>

            {this.render_record()}
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({gift_details}: ApplicationState) => ({
  loading: gift_details.loading,
  errors: gift_details.errors,
  updated: gift_details.updated,
  data: gift_details.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (data: GiftObjectRequest) => dispatch(
    giftActions.giftFetchRequest(data)
  ),
  updateRequest: (data: GiftObject) => dispatch(
    giftActions.giftUpdateRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftsDetails);
