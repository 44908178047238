import React, {Component, useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router'; 
import {Link} from 'react-router-dom'; 
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as wheelGiftActions from '../../store/wheel_gifts/actions';
import {
  WheelGiftObject,
  WheelGiftObjectRequest
} from '../../store/wheel_gifts/types';

import {
  Breadcrumb,
  Button,
  Column,
  Control,
  Field,
  Generic,
  Input,
  Label,
  Level,
  Notification,
  Section,
  Textarea
} from 'rbx';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import yaml from 'js-yaml';
import _ from 'lodash'

export const AceInput = (props: any) => {
  const onChange = (val: string) => {
    setValue(val)
    if (props.mode == 'yaml') {
      try {
        setStringValue(JSON.stringify(yaml.load(val)))
      } catch (e) {
        console.log('====error', e)
      }
    } else {
      setStringValue(val)
    }
  }

  const [value, setValue] = useState('')
  const [stringValue, setStringValue] = useState('')
  useEffect(() => {
    setStringValue(props.defaultValue)
    try {
      if (props.mode == 'json') {
        let json = JSON.parse(props.defaultValue)
        setValue(JSON.stringify(json, null, 2))
      } else if (props.mode == 'yaml') {
        let yml = yaml.dump(JSON.parse(props.defaultValue))
        setValue(yml)
      } else {
        setValue(props.defaultValue)
      }
    } catch (e) {
      setValue(props.defaultValue)
    }
  }, [props.defaultValue])

  return (
    <>
      <AceEditor
        mode={props.mode}
        theme="terminal"
        onChange={onChange}
        editorProps={{ $blockScrolling: true }}
        width="440px"
        height="300px"
        value={value}
      />
      <textarea name={props.name} value={stringValue} style={{ display: 'none' }} />
    </>
  )
}

interface WheelGiftsDetails {
  [key: string]: any;
}

interface PropsFromState {
  loading: boolean,
  errors: string | undefined,
  updated: boolean,
  data: WheelGiftObject,
}

interface PropsFromDispatch {
  fetchRequest: typeof wheelGiftActions.wheelGiftFetchRequest,
  updateRequest: typeof wheelGiftActions.wheelGiftUpdateRequest,
}

type Props = RouteComponentProps & PropsFromState & PropsFromDispatch & ConnectedReduxProps;

interface State {
}

class WheelGiftsDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    const {match, history} = this.props;
    const params = match.params as any
    if (params.id != 'add') {
      this.props.fetchRequest(match.params);
    }
  }

  public key(prefix: string) {
    const {data} = this.props;
    return `${prefix}_${data.id}`;
  }

  public update_record(event: React.FormEvent<HTMLFormElement>) {
    const numberFromString = (str: any) => {
      if (!str) return undefined
      return Number(str)
    }

    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    let budget = Number(data.get('budget'))
    let quantity = Number(data.get('quantity'))
    const price = Number(data.get('price'))
    let isLixiOrNone = false
    try {
      let user_params = JSON.parse(data.get('user_params') as string);
      isLixiOrNone = user_params.type == 'lixi' || user_params.type == 'none'
    } catch (error) {
      console.log(error)
    }

    if (isLixiOrNone) {
       budget = quantity * price
    } else {
      if (price > 0 && budget >= 0) {
        quantity = Math.floor(budget / price)
      }
    }

    // const rate_from = Number(data.get('rate_from'))
    // const rate_to = Number(data.get('rate_to'))
    
    const payload = {
      id: data.get('id') as string,
      game_id: data.get('game_id') as string,
      campaign_id: data.get('campaign_id') as string,
      voucher_id: data.get('voucher_id') as string,
      group_id: data.get('group_id') as string,
      background: data.get('background') as string,
      image: data.get('image') as string,
      win_image: data.get('win_image') as string,
      name: data.get('name') as string,
      budget,
      quantity,
      price,
      level: numberFromString(data.get('level')),
      // rate_from: rate_from,
      // rate_to: rate_to,
      win_text: data.get('win_text') as string,
      use_text: data.get('use_text') as string,
      status: numberFromString(data.get('status')),
      use_params: data.get('use_params') as string,
    } as any;
    for (let key of Object.keys(payload)) {
      if (payload[key] === undefined) {
        delete payload[key]
      } else {
        if (Number.isNaN(payload[key])) {
          alert(`${key} must input number`)
          return
        }
      }
    }
    payload.create_time = undefined
    payload.update_time = undefined
    //this.props.updateRequest(payload);
    this.props.updateRequest({ ...this.props.data, ...payload });
  }
  
  public render_record() {
    const {match, data, updated, errors} = this.props;    

    let isLixiOrNone = false
    try {
      let user_params = JSON.parse(data.use_params);
      isLixiOrNone = user_params.type == 'lixi' || user_params.type == 'none'
    } catch (error) {
      console.log(error)
    }
    return <form onSubmit={this.update_record.bind(this)}>
      <Column.Group>
        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>ID</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('id')}
                    static
                    type="text"
                    name="id"
                    defaultValue={data.id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Game Id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('game_id')}
                    type="text"
                    name="game_id"
                    defaultValue={data.game_id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Campaign id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('campaign_id')}
                    type="text"
                    name="campaign_id"
                    defaultValue={data.campaign_id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Voucher id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('voucher_id')}
                    type="text"
                    name="voucher_id"
                    defaultValue={data.voucher_id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
          
          <Field horizontal>
            <Field.Label size="normal">
              <Label>Group id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('group_id')}
                    type="text"
                    name="group_id"
                    defaultValue={data.group_id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Background color</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('background')}
                    type="text"
                    placeholder="(empty)"
                    name="background"
                    maxLength="128"
                    defaultValue={data.background}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Image url</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('image')}
                    placeholder="(empty)"
                    rows={3}
                    maxLength="1024"
                    name="image"
                    defaultValue={data.image}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Win image url</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('win_image')}
                    placeholder="(empty)"
                    rows={3}
                    maxLength="1024"
                    name="win_image"
                    defaultValue={data.win_image}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Name</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('name')}
                    type="text"
                    placeholder="(empty)"
                    name="name"
                    maxLength="128"
                    defaultValue={data.name}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>quantity</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('quantity')}
                    static={!isLixiOrNone}
                    type="number"
                    name="quantity"
                    defaultValue={data.quantity}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Budget</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('budget')}
                    static={isLixiOrNone}
                    type="number"
                    name="gems"
                    defaultValue={data.budget}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Price</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('price')}
                    type="number"
                    name="price"
                    defaultValue={data.price}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Level</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('level')}
                    type="number"
                    name="level"
                    defaultValue={data.level}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Status</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('status')}
                    type="number"
                    name="status"
                    defaultValue={data.status}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Rate from</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('rate_from')}
                    static
                    type="number"
                    name="rate_from"
                    defaultValue={data.rate_from}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Rate to</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('rate_to')}
                    static
                    type="number"
                    name="rate_to"
                    defaultValue={data.rate_to}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Win info</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  {/*<Textarea
                    key={this.key('win_text')}
                    placeholder="(empty)"
                    rows={7}
                    name="win_text"
                    defaultValue={data.win_text}
                  />*/}
                  <AceInput
                    mode="html"
                    name="win_text"
                    defaultValue={data.win_text}
                  />

                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Use info</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  {/*<Textarea
                    key={this.key('use_text')}
                    placeholder="(empty)"
                    rows={7}
                    name="use_text"
                    defaultValue={data.use_text}
                  />*/}
                  <AceInput
                    mode="yaml"
                    name="use_text"
                    defaultValue={data.use_text}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Use item parameters</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <AceInput
                    key={this.key('use_params')}
                    mode="yaml"
                    name="use_params"
                    defaultValue={data.use_params}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field kind="group" align="right">
            {
              updated ?
                <Notification color="success">Successfully updated user profile record.</Notification> :
                null
            }
            {
              errors ?
                <Notification color="danger">{errors}</Notification> :
                null
            }
            &nbsp;
            <Control>
              <Button color="info">Update</Button>
            </Control>
          </Field>
        </Column>

        <Column size={6}>
        </Column>
      </Column.Group>
    </form>;

  }

  public render() {
    const {match} = this.props;
    return <Generic id="gifts_details">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="gifts"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Breadcrumb>
                    <Breadcrumb.Item as="span"><Link to="/wheel_gifts">Gifts</Link></Breadcrumb.Item>
                  </Breadcrumb>
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                </Level.Item>
              </Level.Item>
            </Level>

            {this.render_record()}
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({wheel_gift_details}: ApplicationState) => ({
  loading: wheel_gift_details.loading,
  errors: wheel_gift_details.errors,
  updated: wheel_gift_details.updated,
  data: wheel_gift_details.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (data: WheelGiftObjectRequest) => dispatch(
    wheelGiftActions.wheelGiftFetchRequest(data)
  ),
  updateRequest: (data: WheelGiftObject) => dispatch(
    wheelGiftActions.wheelGiftUpdateRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WheelGiftsDetails);
