export interface WheelGiftObjectRequest {
  id?: string,
};

export interface WheelGiftObject {
  id: string
  game_id: string
  campaign_id: string
  voucher_id: string
  group_id: string
  background: string
  image: string
  win_image: string
  name: string
  budget: number
  quantity: number
  price: number
  level: number
  win_text: string
  use_text: string
  rules: string
  status: number
  rate_from: number
  rate_to: number
  use_params: string
};

export interface WheelGiftsObjectRequest {
  filter?: string,
  game?: string,
};

export interface WheelGiftsObject {
  gifts: WheelGiftObject[],
  total_count: number,
};

export enum WheelGiftActionTypes {
  SELECT = '@@wheel_gift/SELECT',
  FETCH_MANY_REQUEST = '@@wheel_gift/FETCH_MANY_REQUEST',
  FETCH_MANY_SUCCESS = '@@wheel_gift/FETCH_MANY_SUCCESS',
  FETCH_MANY_ERROR = '@@wheel_gift/FETCH_MANY_ERROR',
  FETCH_REQUEST = '@@wheel_gift/FETCH_REQUEST',
  FETCH_SUCCESS = '@@wheel_gift/FETCH_SUCCESS',
  FETCH_ERROR = '@@wheel_gift/FETCH_ERROR',
  UPDATE_REQUEST = '@@wheel_gift/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@@wheel_gift/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@wheel_gift/UPDATE_ERROR',
};

export interface WheelGiftsState {
  readonly loading: boolean,
  readonly data: WheelGiftsObject,
  readonly errors?: string,
  selected?: WheelGiftObject,
};

export interface WheelGiftState {
  readonly loading: boolean,
  readonly updated: boolean,
  readonly errors?: string
  readonly data?: any,
};
