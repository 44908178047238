import {action} from 'typesafe-actions';
import {
  GiftActionTypes,
  GiftObjectRequest,
  GiftObject,
  GiftsObjectRequest,
  GiftsObject,
} from './types';

export const giftSelectRequest = (data: any) => action(
  GiftActionTypes.SELECT,
  data
);

export const giftFetchManyRequest = (data: GiftsObjectRequest) => action(
  GiftActionTypes.FETCH_MANY_REQUEST,
  data
);
export const giftFetchManySuccess = (data: GiftsObject) => action(
  GiftActionTypes.FETCH_MANY_SUCCESS,
  data
);
export const giftFetchManyError = (message: string) => action(
  GiftActionTypes.FETCH_MANY_ERROR,
  message
);

export const giftFetchRequest = (data: GiftObjectRequest) => action(
  GiftActionTypes.FETCH_REQUEST,
  data
);
export const giftFetchSuccess = (data: GiftObject) => action(
  GiftActionTypes.FETCH_SUCCESS,
  data
);
export const giftFetchError = (message: string) => action(
  GiftActionTypes.FETCH_ERROR,
  message
);

export const giftUpdateRequest = (data: GiftObject) => action(
  GiftActionTypes.UPDATE_REQUEST,
  data
);
export const giftUpdateSuccess = () => action(
  GiftActionTypes.UPDATE_SUCCESS
);
export const giftUpdateError = (message: string) => action(
  GiftActionTypes.UPDATE_ERROR,
  message
);
