import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom'; 
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as gameActions from '../../store/games/actions';
import {
  GameObject,
  GameObjectRequest
} from '../../store/games/types';
import moment from 'moment'

import {
  Breadcrumb,
  Button,
  Column,
  Control,
  Field,
  Generic,
  Input,
  Label,
  Level,
  Notification,
  Section,
  Textarea
} from 'rbx';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import { AceInput } from '../../routes/wheel_gifts/details'

interface GamesDetails {
  [key: string]: any;
}

interface PropsFromState {
  loading: boolean,
  errors: string | undefined,
  updated: boolean,
  data: GameObject,
}

interface PropsFromDispatch {
  fetchRequest: typeof gameActions.gameFetchRequest,
  updateRequest: typeof gameActions.gameUpdateRequest,
}

type Props = RouteComponentProps & PropsFromState & PropsFromDispatch & ConnectedReduxProps;

interface State {
}

class GamesDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    const {match, history} = this.props;
    const params = match.params as any
    if (params.id != 'add') {
      this.props.fetchRequest(match.params);
    }
  }

  public key(prefix: string) {
    const {data} = this.props;
    return `${prefix}_${data.id}`;
  }

  public update_record(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    let from_date = formData.get('from_date') as string
    let from_time = formData.get('from_time') as string
    let to_date = formData.get('to_date') as string
    let to_time = formData.get('to_time') as string
    const payload = {
      id: formData.get('id') as string,
      name: formData.get('name') as string,
      logo: formData.get('logo') as string,
      cover: formData.get('cover') as string,
      description: formData.get('description') as string,
      groupid: formData.get('groupid') as string,
      status: formData.get('status') as string,
      url: formData.get('url') as string,
      from_date: `${from_date} ${from_time}:00+07:00`,
      to_date: `${to_date} ${to_time}:00+07:00`,
      average_game_for_one_win: formData.get('average_game_for_one_win') as string,
      config: formData.get('config') as string,
      tester: formData.get('tester') as string,
    } as any;
    for (let key of Object.keys(payload)) {
      if (payload[key] === undefined) {
        delete payload[key]
      } else {
        if (Number.isNaN(payload[key])) {
          alert(`${key} must input number`)
          return
        }
      }
    }
    payload.create_time = undefined
    payload.update_time = undefined
    //console.log('update data', this.props.data, payload, {...this.props.data, ...payload })
    this.props.updateRequest({ ...this.props.data, ...payload });
  }
  
  public render_record() {
    const {match, data, updated, errors} = this.props;
    
    return <form onSubmit={this.update_record.bind(this)}>
      <Column.Group>
        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>ID</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('id')}
                    static
                    type="text"
                    name="id"
                    defaultValue={data.id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Name</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('name')}
                    type="text"
                    placeholder="(empty)"
                    name="name"
                    maxLength="128"
                    defaultValue={data.name}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Description</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('description')}
                    type="text"
                    placeholder="(empty)"
                    name="description"
                    rows={6}
                    defaultValue={data.description}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Logo url</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('logo')}
                    placeholder="(empty)"
                    rows={3}
                    maxLength="1024"
                    name="logo"
                    defaultValue={data.logo}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
          
          <Field horizontal>
            <Field.Label size="normal">
              <Label>Cover url</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('cover')}
                    placeholder="(empty)"
                    rows={3}
                    maxLength="1024"
                    name="cover"
                    defaultValue={data.cover}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Group id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('groupid')}
                    type="number"
                    placeholder="(empty)"
                    name="groupid"
                    maxLength="128"
                    defaultValue={data.groupid}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Status</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('status')}
                    type="number"
                    placeholder="(empty)"
                    name="status"
                    maxLength="128"
                    defaultValue={data.status}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Game url</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('url')}
                    placeholder="(empty)"
                    rows={3}
                    maxLength="1024"
                    name="url"
                    defaultValue={data.url}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>From date</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('from_date')}
                    type="date"
                    placeholder="(empty)"
                    name="from_date"
                    defaultValue={moment((data.from_date || 0) * 1000).format('YYYY-MM-DD')}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>From time</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('from_time')}
                    type="time"
                    placeholder="(empty)"
                    name="from_time"
                    defaultValue={moment((data.from_date || 0) * 1000).format('HH:mm')}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>To date</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('to_date')}
                    type="date"
                    placeholder="(empty)"
                    name="to_date"
                    defaultValue={moment((data.to_date || 0) * 1000).format('YYYY-MM-DD')}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>To time</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('to_time')}
                    type="time"
                    placeholder="(empty)"
                    name="to_time"
                    defaultValue={moment((data.to_date || 0) * 1000).format('HH:mm')}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
          
          <Field horizontal>
            <Field.Label size="normal">
              <Label>Average games for 1 win game</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('average_game_for_one_win')}
                    type="number"
                    placeholder="(empty)"
                    step="0.01"
                    name="average_game_for_one_win"
                    defaultValue={data.average_game_for_one_win}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>Config</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                 {/*<Textarea
                    key={this.key('config')}
                    placeholder="(empty)"
                    rows={3}
                    maxLength="1024"
                    name="config"
                    defaultValue={data.config}
                  />*/}
                  <AceInput
                    mode="yaml"
                    name="config"
                    defaultValue={data.config}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>Testers</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <AceInput
                    mode="yaml"
                    name="tester"
                    defaultValue={data.tester}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field kind="group" align="right">
            {
              updated ?
                <Notification color="success">Successfully updated user profile record.</Notification> :
                null
            }
            {
              errors ?
                <Notification color="danger">{errors}</Notification> :
                null
            }
            &nbsp;
            <Control>
              <Button color="info">Update</Button>
            </Control>
          </Field>
        </Column>

        <Column size={6}>
        </Column>
      </Column.Group>
    </form>;

  }

  public render() {
    const {match} = this.props;
    return <Generic id="games_details">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="games"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Breadcrumb>
                    <Breadcrumb.Item as="span"><Link to="/games">Games</Link></Breadcrumb.Item>
                  </Breadcrumb>
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                </Level.Item>
              </Level.Item>
            </Level>

            {this.render_record()}
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({game_details}: ApplicationState) => ({
  loading: game_details.loading,
  errors: game_details.errors,
  updated: game_details.updated,
  data: game_details.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (data: GameObjectRequest) => dispatch(
    gameActions.gameFetchRequest(data)
  ),
  updateRequest: (data: GameObject) => dispatch(
    gameActions.gameUpdateRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GamesDetails);
