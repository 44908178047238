import {action} from 'typesafe-actions';
import {
  GameActionTypes,
  GameObjectRequest,
  GameObject,
  GamesObjectRequest,
  GamesObject,
} from './types';

export const gameSelectRequest = (data: any) => action(
  GameActionTypes.SELECT,
  data
);

export const gameFetchManyRequest = (data: GamesObjectRequest) => action(
  GameActionTypes.FETCH_MANY_REQUEST,
  data
);
export const gameFetchManySuccess = (data: GamesObject) => action(
  GameActionTypes.FETCH_MANY_SUCCESS,
  data
);
export const gameFetchManyError = (message: string) => action(
  GameActionTypes.FETCH_MANY_ERROR,
  message
);

export const gameFetchRequest = (data: GameObjectRequest) => action(
  GameActionTypes.FETCH_REQUEST,
  data
);
export const gameFetchSuccess = (data: GameObject) => action(
  GameActionTypes.FETCH_SUCCESS,
  data
);
export const gameFetchError = (message: string) => action(
  GameActionTypes.FETCH_ERROR,
  message
);

export const gameUpdateRequest = (data: GameObject) => action(
  GameActionTypes.UPDATE_REQUEST,
  data
);
export const gameUpdateSuccess = () => action(
  GameActionTypes.UPDATE_SUCCESS
);
export const gameUpdateError = (message: string) => action(
  GameActionTypes.UPDATE_ERROR,
  message
);
