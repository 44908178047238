import {Reducer} from 'redux';
import {UserGiftState, UserGiftsState, UserGiftActionTypes} from './types';
import _ from 'lodash'

const initialUserGiftsState: UserGiftsState = {
  data: {
    gifts: [],
    total_count: 0
  },
  selected: undefined,
  errors: undefined,
  loading: false
};

export const userGiftsReducer: Reducer<UserGiftsState> = (state = initialUserGiftsState, action) => {
  switch(action.type) {
    case UserGiftActionTypes.SELECT:
      return {...state, selected: action.payload};

    case UserGiftActionTypes.FETCH_MANY_REQUEST:
      return {...state, loading: true};
    case UserGiftActionTypes.FETCH_MANY_SUCCESS: {
      let { selected } = state
      let data = action.payload.payload
      if (selected) {
        let found = _.find(data.gifts, {id: selected.id})
        if (found) selected = found
      }
      return {...state, loading: false, errors: undefined, selected, data};
    }
    case UserGiftActionTypes.FETCH_MANY_ERROR:
      return {...state, loading: false, errors: action.payload, data: initialUserGiftsState.data};
    default:
      return state;
  }
}

const initialUserGiftState: UserGiftState = {
  updated: false,
  errors: undefined,
  loading: false,
  data: {
    id: '',
    name: '',
    description: '',
    image_url: '',
    coins: 0,
    gems: 0,
  }
};

export const userGiftReducer: Reducer<UserGiftState> = (state = initialUserGiftState, action) => {
  switch (action.type) {
    case UserGiftActionTypes.FETCH_REQUEST:
      return {...state, loading: true};
    case UserGiftActionTypes.FETCH_SUCCESS:
      return {...state, loading: false, errors: undefined, data: action.payload.payload};
    case UserGiftActionTypes.FETCH_ERROR:
      return {...state, loading: false, errors: action.payload};

    case UserGiftActionTypes.UPDATE_REQUEST:
      return {...state, loading: true};
    case UserGiftActionTypes.UPDATE_SUCCESS:
      return {...state, loading: false, errors: undefined, updated: true};
    case UserGiftActionTypes.UPDATE_ERROR:
      return {...state, loading: false, errors: action.payload, updated: false};
    default:
      return state;
  }
}
