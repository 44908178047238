export interface UserGiftObjectRequest {
  id?: string,
};

export interface UserGiftObject {
  id: string
  user_id: string
  gift_id: string
  game_id: string
  metadata: string
  status: number
  create_time: string
  update_time: string
  user_full_name?: string
  gift_name?: string
  gift_price?: number
};

export interface UserGiftsObjectRequest {
  filter?: string,
};

export interface UserGiftsObject {
  gifts: UserGiftObject[],
  total_count: number,
};

export enum UserGiftActionTypes {
  SELECT = '@@user_gift/SELECT',
  FETCH_MANY_REQUEST = '@@user_gift/FETCH_MANY_REQUEST',
  FETCH_MANY_SUCCESS = '@@user_gift/FETCH_MANY_SUCCESS',
  FETCH_MANY_ERROR = '@@user_gift/FETCH_MANY_ERROR',
  FETCH_REQUEST = '@@user_gift/FETCH_REQUEST',
  FETCH_SUCCESS = '@@user_gift/FETCH_SUCCESS',
  FETCH_ERROR = '@@user_gift/FETCH_ERROR',
  UPDATE_REQUEST = '@@user_gift/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@@user_gift/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@user_gift/UPDATE_ERROR',
};

export interface UserGiftsState {
  readonly loading: boolean,
  readonly data: UserGiftsObject,
  readonly errors?: string,
  selected?: UserGiftObject,
};

export interface UserGiftState {
  readonly loading: boolean,
  readonly updated: boolean,
  readonly errors?: string
  readonly data?: any,
};
