import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import queryString from 'query-string';

import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as userGiftActions from '../../store/user_gifts/actions';
import {UserGiftObject, UserGiftObjectRequest, UserGiftsObject, UserGiftsObjectRequest} from '../../store/user_gifts/types';

import {Button, Column, Control, Field, Generic, Icon, Input, Level, Section, Table, Title} from 'rbx';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import moment from 'moment';

interface UserPropsFromState {
  loading: boolean,
  errors: string | undefined,
  data: UserGiftsObject,
  selected?: UserGiftObject,
}

interface UserPropsFromDispatch {
  fetchManyRequest: typeof userGiftActions.userGiftFetchManyRequest,
  selectRequest: typeof userGiftActions.userGiftSelectRequest,
}

type UserProps = RouteComponentProps & UserPropsFromState & UserPropsFromDispatch & ConnectedReduxProps;

type UserState = {
  filter: string,
};

class UserGifts extends Component<UserProps, UserState> {
  public constructor(props: UserProps) {
    super(props);
    this.state = {filter: ''};
  }

  public componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.filter) {
      (document.getElementById('filter') as HTMLInputElement).value = query.filter as string;
    }
    this.setState({
      filter: query.filter as string || '',
    });
    this.props.fetchManyRequest({
      filter: query.filter as string || '',
    });
  }

  public all() {
    this.props.fetchManyRequest({
      filter: this.state.filter,
    });
  }

  public filter() {
    const {history} = this.props;
    const filter = (document.getElementById('filter') as HTMLInputElement).value;
    history.push(`/user_gifts?filter=${filter}`);
    this.setState({filter});
    this.props.fetchManyRequest({filter});
  }

  public details(id: string) {
    //const {history} = this.props;
    //history.push(`/user_gifts/${id}`);
  }

  public add() {
    const {history} = this.props;
    history.push(`/user_gifts/add`);
  }

  public render() {
    const {data, selected} = this.props;
    console.log('data', data)
    const selected_id = selected && selected.id;

    return <Generic id="gifts">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="user_gifts"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Title subtitle size={5}>
                    ~<strong>{data.total_count}</strong> items
                  </Title>
                </Level.Item>

                <Level.Item>
                  <Field kind="addons">
                    <Control>
                      <Input id="filter" type="text" placeholder="User"/>
                    </Control>
                    <Control>
                      <Button onClick={this.filter.bind(this)}>Lookup</Button>
                    </Control>
                  </Field>
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                  <Control>
                    <Button onClick={this.add.bind(this)}>Create</Button>
                  </Control>
                </Level.Item>
              </Level.Item>
            </Level>

            <Table fullwidth striped hoverable>
              <Table.Head>
                <Table.Row>
                  <Table.Heading style={{width: '8%'}}>User id / fullname</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Gift id / name</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Price</Table.Heading>
                  <Table.Heading style={{width: '15%'}}>Meta data</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Time</Table.Heading>
                  <Table.Heading style={{width: '5%'}}>status</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {
                  data.gifts && data.gifts.length && data.gifts.map((gift, key) =>
                    <Table.Row
                      key={`cell_${key}`}
                      selected={selected_id === gift.id}
                      onClick={this.details.bind(this, gift.id)}
                    >
                      <Table.Cell>{gift.user_id}<br/>{gift.user_full_name}</Table.Cell>
                      <Table.Cell>{gift.gift_id}<br/>{gift.gift_name}</Table.Cell>
                      <Table.Cell>{Number(gift.gift_price || 0).toLocaleString()}</Table.Cell>
                      <Table.Cell>{gift.metadata}</Table.Cell>
                      <Table.Cell>{moment(Number(gift.create_time) * 1000).format('DD/MM HH:mm:ss')}</Table.Cell>
                      <Table.Cell>{gift.status}</Table.Cell>
                    </Table.Row>
                  )
                }
              </Table.Body>
            </Table>
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({user_gift}: ApplicationState) => ({
  loading: user_gift.loading,
  errors: user_gift.errors,
  data: user_gift.data,
  selected: user_gift.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchManyRequest: (data: UserGiftsObjectRequest) => dispatch(
    userGiftActions.userGiftFetchManyRequest(data)
  ),
  selectRequest: (data: UserGiftObject) => dispatch(
    userGiftActions.userGiftSelectRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGifts);
