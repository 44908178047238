import {Reducer} from 'redux';
import {DemoState, DemoActionTypes} from './types';

const initialDemoState: DemoState = {
  errors: undefined,
  loading: false,
  created_user: undefined,
  topup: undefined,
  action: undefined,
  consume_gems: undefined,
  exchange_gift: undefined,
};

export const demoReducer: Reducer<DemoState> = (state = initialDemoState, action) =>
{
  switch(action.type)
  {
    case DemoActionTypes.CREATE_USER_REQUEST:
      return {...state, loading: true, errors: undefined, created_user: undefined};
    case DemoActionTypes.CREATE_USER_SUCCESS:
      return {...state, loading: false, errors: undefined, created_user: action.payload};
    case DemoActionTypes.CREATE_USER_ERROR:
      return {...state, loading: false, errors: action.payload, created_user: undefined};

    case DemoActionTypes.DEMO_TOPUP_REQUEST:
      return {...state, loading: true, errors: undefined, topup: undefined};
    case DemoActionTypes.DEMO_TOPUP_SUCCESS:
      return {...state, loading: false, errors: undefined, topup: action.payload};
    case DemoActionTypes.DEMO_TOPUP_ERROR:
      return {...state, loading: false, errors: action.payload, topup: undefined};

    case DemoActionTypes.DEMO_ACTION_REQUEST:
      return {...state, loading: true, errors: undefined, action: undefined};
    case DemoActionTypes.DEMO_ACTION_SUCCESS:
      return {...state, loading: false, errors: undefined, action: action.payload};
    case DemoActionTypes.DEMO_ACTION_ERROR:
      return {...state, loading: false, errors: action.payload, action: undefined};

    case DemoActionTypes.DEMO_CONSUME_GEMS_REQUEST:
      return {...state, loading: true, errors: undefined, consume_gems: undefined};
    case DemoActionTypes.DEMO_CONSUME_GEMS_SUCCESS:
      return {...state, loading: false, errors: undefined, consume_gems: action.payload};
    case DemoActionTypes.DEMO_CONSUME_GEMS_ERROR:
      return {...state, loading: false, errors: action.payload, consume_gems: undefined};

    case DemoActionTypes.DEMO_GET_CONFIG_REQUEST:
      return {...state, loading: true, errors: undefined, config: undefined};
    case DemoActionTypes.DEMO_GET_CONFIG_SUCCESS:
      return {...state, loading: false, errors: undefined, config: action.payload};
    case DemoActionTypes.DEMO_GET_CONFIG_ERROR:
      return {...state, loading: false, errors: action.payload, config: undefined};

    case DemoActionTypes.DEMO_SET_CONFIG_REQUEST:
      return {...state, loading: true, errors: undefined, updated_config: undefined};
    case DemoActionTypes.DEMO_SET_CONFIG_SUCCESS:
      return {...state, loading: false, errors: undefined, updated_config: action.payload};
    case DemoActionTypes.DEMO_SET_CONFIG_ERROR:
      return {...state, loading: false, errors: action.payload, updated_config: undefined};

    case DemoActionTypes.DEMO_EXCHANGE_GIFT_REQUEST:
      return {...state, loading: true, errors: undefined, exchange_gift: undefined};
    case DemoActionTypes.DEMO_EXCHANGE_GIFT_SUCCESS:
      return {...state, loading: false, errors: undefined, exchange_gift: action.payload};
    case DemoActionTypes.DEMO_EXCHANGE_GIFT_ERROR:
      return {...state, loading: false, errors: action.payload, exchange_gift: undefined};

    default:
      return state;
  }
}
