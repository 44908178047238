export interface GiftObjectRequest {
  id?: string,
};

export interface GiftObject {
  id: string,
  name?: string,
  description?: string,
  image_url?: string,
  coins?: number,
  gems?: number,
  quantity?: number,
  voucher?: string,
};

export interface GiftsObjectRequest {
  filter?: string,
};

export interface GiftsObject {
  gifts: GiftObject[],
  total_count: number,
};

export enum GiftActionTypes {
  SELECT = '@@gift/SELECT',
  FETCH_MANY_REQUEST = '@@gift/FETCH_MANY_REQUEST',
  FETCH_MANY_SUCCESS = '@@gift/FETCH_MANY_SUCCESS',
  FETCH_MANY_ERROR = '@@gift/FETCH_MANY_ERROR',
  FETCH_REQUEST = '@@gift/FETCH_REQUEST',
  FETCH_SUCCESS = '@@gift/FETCH_SUCCESS',
  FETCH_ERROR = '@@gift/FETCH_ERROR',
  UPDATE_REQUEST = '@@gift/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@@gift/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@gift/UPDATE_ERROR',
};

export interface GiftsState {
  readonly loading: boolean,
  readonly data: GiftsObject,
  readonly errors?: string,
  selected?: GiftObject,
};

export interface GiftState {
  readonly loading: boolean,
  readonly updated: boolean,
  readonly errors?: string
  readonly data?: any,
};
