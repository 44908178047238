import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import queryString from 'query-string';

import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as wheelGiftActions from '../../store/wheel_gifts/actions';
import {WheelGiftObject, WheelGiftObjectRequest, WheelGiftsObject, WheelGiftsObjectRequest} from '../../store/wheel_gifts/types';

import {Button, Column, Control, Field, Generic, Icon, Input, Level, Section, Table, Title} from 'rbx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import _ from 'lodash'

interface WheelPropsFromState {
  loading: boolean,
  errors: string | undefined,
  data: WheelGiftsObject,
  selected?: WheelGiftObject,
}

interface WheelPropsFromDispatch {
  fetchManyRequest: typeof wheelGiftActions.wheelGiftFetchManyRequest,
  selectRequest: typeof wheelGiftActions.wheelGiftSelectRequest,
}

type WheelProps = RouteComponentProps & WheelPropsFromState & WheelPropsFromDispatch & ConnectedReduxProps;

var filter = ''

type WheelState = {
  filter: string,
  game: string,
};

class WheelGifts extends Component<WheelProps, WheelState> {
  public constructor(props: WheelProps) {
    super(props);
    this.state = {filter: '', game: ''};
  }

  public componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const _filter = (query.filter || filter) as string
    if (_filter) {
      (document.getElementById('filter') as HTMLInputElement).value = _filter;
    }
    this.setState({
      filter: _filter,
      game: query.game as string || '',
    });
    this.props.fetchManyRequest({
      filter: _filter,
      game: query.game as string || '',
    });
  }

  public all() {
    this.props.fetchManyRequest({
      filter: this.state.filter,
      game: this.state.game,
    });
  }

  public filter() {
    const {history} = this.props;
    const _filter = (document.getElementById('filter') as HTMLInputElement).value;
    // history.push(`/wheel_gifts?filter=${filter}`);
    filter = _filter
    this.setState({filter});
    this.props.fetchManyRequest({filter});
  }

  public details(id: string) {
    const {history} = this.props;
    history.push(`/wheel_gifts/${id}`);
  }

  public add() {
    const {history} = this.props;
    history.push(`/wheel_gifts/add`);
  }

  public select_gift(gift: WheelGiftObject | undefined, event: React.FormEvent<Element>) {
    event.stopPropagation();
    event.preventDefault();
    const { selectRequest } = this.props
    selectRequest(gift)
    if (gift) {
      const { history, location } = this.props;
      const query = queryString.parse(location.search) as any;
      if (query.back) history.push(query.back);
    }
  }

  public render() {
    const {data, selected} = this.props;
    console.log('data', data)
    const selected_id = selected && selected.id;

    const max_range_obj = _.maxBy(data.gifts, it => Number(it.rate_to))
    const max_range = max_range_obj ? Number(max_range_obj.rate_to) : 0

    return <Generic id="gifts">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="wheel_gifts"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Title subtitle size={5}>
                    ~<strong>{data.total_count}</strong> items
                  </Title>
                </Level.Item>

                <Level.Item>
                  <Field kind="addons">
                    <Control>
                      <Input id="filter" type="text" placeholder="Find a gift"  />
                    </Control>
                    <Control>
                      <Button onClick={this.filter.bind(this)}>Lookup</Button>
                    </Control>
                  </Field>
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                  <Control>
                    <Button onClick={this.add.bind(this)}>Create</Button>
                  </Control>
                </Level.Item>
              </Level.Item>
            </Level>

            <Table fullwidth striped hoverable>
              <Table.Head>
                <Table.Row>
                  <Table.Heading style={{width: '8%'}}>Campaign id<br/>Voucher id</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Game</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Group</Table.Heading>
                  <Table.Heading style={{width: '17.5%'}}>Name</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Price</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Random range</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Rate</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Image</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Win image</Table.Heading>
                  <Table.Heading style={{width: '5%'}}>Status</Table.Heading>
                  <Table.Heading style={{width: '5%'}}>Select</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {
                  data.gifts && data.gifts.length && data.gifts.map((gift, key) =>
                    <Table.Row
                      key={`cell_${key}`}
                      selected={selected_id === gift.id}
                    >
                      <Table.Cell>{gift.campaign_id}<br/>{gift.voucher_id}</Table.Cell>
                      <Table.Cell>{gift.game_id}</Table.Cell>
                      <Table.Cell>{gift.group_id}</Table.Cell>
                      <Table.Cell>{gift.name}</Table.Cell>
                      <Table.Cell>{Number(gift.price).toLocaleString()}</Table.Cell>
                      <Table.Cell>{gift.rate_from}<br/>{gift.rate_to}</Table.Cell>
                      <Table.Cell>{max_range && gift.rate_to && gift.rate_from ? ((Number(gift.rate_to!) - Number(gift.rate_from!))/max_range! * 100).toFixed(6) + '%' : ''}</Table.Cell>
                      <Table.Cell>{gift.image && <img src={gift.image} style={{ maxWidth: 80, maxHeight: 80 }} />}</Table.Cell>
                      <Table.Cell>{gift.win_image && <img src={gift.win_image} style={{ maxWidth: 80, maxHeight: 80 }} />}</Table.Cell>
                      <Table.Cell>{gift.status}</Table.Cell>
                      <Table.Cell>
                        {
                          selected_id !== gift.id
                            ? <Button size="small" onClick={this.select_gift.bind(this, gift)}>Select</Button>
                            : <Button size="small" onClick={this.select_gift.bind(this, undefined)}>Unselect</Button>
                        }
                        <br />
                        <Button style={{ width: '100%', marginTop: 6 }} size="small" onClick={this.details.bind(this, gift.id)}>Edit</Button>
                    </Table.Cell>
                    </Table.Row>
                  )
                }
              </Table.Body>
            </Table>
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({wheel_gift}: ApplicationState) => ({
  loading: wheel_gift.loading,
  errors: wheel_gift.errors,
  data: wheel_gift.data,
  selected: wheel_gift.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchManyRequest: (data: WheelGiftsObjectRequest) => dispatch(
    wheelGiftActions.wheelGiftFetchManyRequest(data)
  ),
  selectRequest: (data: WheelGiftObject) => dispatch(
    wheelGiftActions.wheelGiftSelectRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WheelGifts);
