import {action} from 'typesafe-actions';
import {
  WheelGiftActionTypes,
  WheelGiftObjectRequest,
  WheelGiftObject,
  WheelGiftsObjectRequest,
  WheelGiftsObject,
} from './types';

export const wheelGiftSelectRequest = (data: any) => action(
  WheelGiftActionTypes.SELECT,
  data
);

export const wheelGiftFetchManyRequest = (data: WheelGiftsObjectRequest) => action(
  WheelGiftActionTypes.FETCH_MANY_REQUEST,
  data
);
export const wheelGiftFetchManySuccess = (data: WheelGiftsObject) => action(
  WheelGiftActionTypes.FETCH_MANY_SUCCESS,
  data
);
export const wheelGiftFetchManyError = (message: string) => action(
  WheelGiftActionTypes.FETCH_MANY_ERROR,
  message
);

export const wheelGiftFetchRequest = (data: WheelGiftObjectRequest) => action(
  WheelGiftActionTypes.FETCH_REQUEST,
  data
);
export const wheelGiftFetchSuccess = (data: WheelGiftObject) => action(
  WheelGiftActionTypes.FETCH_SUCCESS,
  data
);
export const wheelGiftFetchError = (message: string) => action(
  WheelGiftActionTypes.FETCH_ERROR,
  message
);

export const wheelGiftUpdateRequest = (data: WheelGiftObject) => action(
  WheelGiftActionTypes.UPDATE_REQUEST,
  data
);
export const wheelGiftUpdateSuccess = () => action(
  WheelGiftActionTypes.UPDATE_SUCCESS
);
export const wheelGiftUpdateError = (message: string) => action(
  WheelGiftActionTypes.UPDATE_ERROR,
  message
);
