import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';

import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as userActions from '../../store/users/actions';
import * as demoActions from '../../store/demo/actions';
import {
  ExportObject,
  UserObject,
  UserObjectRequest
} from '../../store/users/types';
import {
  CreateUserRequest,
  DemoTopupRequest,
  DemoActionRequest,
  DemoConsumeGemsRequest,
  DemoExchangeGiftRequest,
  DemoGetConfigRequest,
  DemoSetConfigRequest,
} from '../../store/demo/types';

import {
  Breadcrumb,
  Button,
  Column,
  Control,
  Field,
  Generic,
  Icon,
  Input,
  Label,
  Level,
  Notification,
  Section,
  Tab,
  Table,
  Tag,
  Textarea,
  Select,
} from 'rbx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import {
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

interface Demo {
  [key: string]: any;
}

interface PropsFromState {
  loading: boolean,
  errors: string | undefined,
  updated: boolean,
  created_user: any,
  topup: any,
  action: any,
  consume_gems: any,
  exchange_gift: any,
  config: any,
  updated_config: any,
  data: ExportObject,
  account: ExportObject,
  selected_gift: any,
  selected_user: any,
}

interface PropsFromDispatch {
  createUserRequest: typeof demoActions.createUserRequest,
  demoTopupRequest: typeof demoActions.demoTopupRequest,
  demoActionRequest: typeof demoActions.demoActionRequest,
  demoConsumeGemsRequest: typeof demoActions.demoConsumeGemsRequest,
  demoExchangeGiftRequest: typeof demoActions.demoExchangeGiftRequest,
  demoGetConfigRequest: typeof demoActions.demoGetConfigRequest,
  demoSetConfigRequest: typeof demoActions.demoSetConfigRequest,
}

type Props = RouteComponentProps & PropsFromState & PropsFromDispatch & ConnectedReduxProps;

type State = {
  tab: string
};

const actions: Array<any> = [
  {
    key: 'pay_invoice',
    text: 'Pay invoice',
    data: {
      amount: 100000,
      type: 'Nước',
    },
  },
  {
    key: 'refer_user',
    text: 'Refer new member',
    data: {
      username: 'nguyenphuquang',
    },
  },
  {
    key: 'use_qr',
    text: 'Use QR code',
    data: {
      amount: 50000,
    },
  },
  {
    key: 'rate_app',
    text: 'Rate app',
    data: {
      rate: 5,
    },
  },
  {
    key: 'comment_app',
    text: 'Comment app',
    data: {},
  },
];

class Demo extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {tab: 'create_user'};
  }

  public componentWillReceiveProps(next: Props) {
    if (
      this.props.data.account.user.id !== next.data.account.user.id &&
      next.data.account.user.id
    ) {
      this.download(next.data);
    }
  }

  public componentDidMount() {
    const { match } = this.props
    const params = match.params as any;
    if (params.tab) {
      this.switch_tab(params.tab);
    }
  }

  public key(prefix: string) {
    const {account} = this.props;
    return `${prefix}_${account.account.user.id}_${account.account.user.update_time}`;
  }

  public switch_tab(tab: string) {
    const {match} = this.props;
    this.setState({tab});
    if (tab == 'demo_config') {
      this.props.demoGetConfigRequest({})
    }
  }

  public render_profile(id: string) {
    const {account, updated, errors} = this.props;
    return <form onSubmit={this.update_profile.bind(this)}>
      <Column.Group>
        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>ID</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('id')}
                    static
                    type="text"
                    name="id"
                    defaultValue={account.account.user.id}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Username</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('username')}
                    type="text"
                    placeholder="(empty)"
                    name="username"
                    maxLength="128"
                    defaultValue={account.account.user.username}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Display Name</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('display_name')}
                    type="text"
                    placeholder="(empty)"
                    name="display_name"
                    maxLength="255"
                    defaultValue={account.account.user.display_name}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Metadata</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('metadata')}
                    placeholder="Metadata"
                    rows={6}
                    name="metadata"
                    defaultValue={account.account.user.metadata}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Create Time</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    static
                    key={this.key('create_time')}
                    type="text"
                    name="create_time"
                    defaultValue={account.account.user.create_time}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Update Time</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    static
                    key={this.key('update_time')}
                    type="text"
                    name="update_time"
                    defaultValue={account.account.user.update_time}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>
        </Column>

        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>Facebook ID</Label>
            </Field.Label>
            <Field.Body>
              <Field kind="addons">
                <Control expanded>
                  <Input
                    disabled
                    key={this.key('facebook_id')}
                    type="text"
                    placeholder="(empty)"
                    name="facebook_id"
                    maxLength="128"
                    defaultValue={account.account.user.facebook_id}
                  />
                </Control>
                <Control>
                  <Button
                    disabled={!account.account.user.facebook_id}
                    onClick={this.unlink.bind(this, 'facebook', "")}
                  >Unlink</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Game Center ID</Label>
            </Field.Label>
            <Field.Body>
              <Field kind="addons">
                <Control expanded>
                  <Input
                    disabled
                    key={this.key('gamecenter_id')}
                    type="text"
                    placeholder="(empty)"
                    name="gamecenter_id"
                    maxLength="128"
                    defaultValue={account.account.user.gamecenter_id}
                  />
                </Control>
                <Control>
                  <Button
                    disabled={!account.account.user.gamecenter_id}
                    onClick={this.unlink.bind(this, 'gamecenter', "")}
                  >Unlink</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Google ID</Label>
            </Field.Label>
            <Field.Body>
              <Field kind="addons">
                <Control expanded>
                  <Input
                    disabled
                    key={this.key('google_id')}
                    type="text"
                    placeholder="(empty)"
                    name="google_id"
                    maxLength="128"
                    defaultValue={account.account.user.google_id}
                  />
                </Control>
                <Control>
                  <Button
                    disabled={!account.account.user.google_id}
                    onClick={this.unlink.bind(this, 'google', "")}
                  >Unlink</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Steam ID</Label>
            </Field.Label>
            <Field.Body>
              <Field kind="addons">
                <Control expanded>
                  <Input
                    disabled
                    key={this.key('steam_id')}
                    type="text"
                    placeholder="(empty)"
                    name="steam_id"
                    maxLength="128"
                    defaultValue={account.account.user.steam_id}
                  />
                </Control>
                <Control>
                  <Button
                    disabled={!account.account.user.steam_id}
                    onClick={this.unlink.bind(this, 'steam', "")}
                  >Unlink</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Avatar URL</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('avatar_url')}
                    type="text"
                    placeholder="(empty)"
                    name="avatar_url"
                    maxLength="512"
                    defaultValue={account.account.user.avatar_url}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Lang Tag</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('lang_tag')}
                    type="text"
                    placeholder="(empty)"
                    name="lang_tag"
                    maxLength="18"
                    defaultValue={account.account.user.lang_tag}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Location</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('location')}
                    type="text"
                    placeholder="(empty)"
                    name="location"
                    maxLength="255"
                    defaultValue={account.account.user.location}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Timezone</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('timezone')}
                    type="text"
                    placeholder="(empty)"
                    name="timezone"
                    maxLength="255"
                    defaultValue={account.account.user.timezone}
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field kind="group" align="right">
            {
              updated ?
                <Notification color="success">Successfully updated user profile record.</Notification> :
                null
            }
            {
              errors ?
                <Notification color="danger">{errors}</Notification> :
                null
            }
            &nbsp;
            <Control>
              <Button color="info">Update</Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>;
  }

  public demo_config(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const {token} = localStorage;
    const session = {token};
    const payload = {
      config: JSON.parse(data.get('config') as string),
    };
    this.props.demoSetConfigRequest(payload);
  }

  public render_demo_config() {
    const {account, config, updated_config, errors} = this.props;
    if (!config) {
      return <h2>Loading...</h2>;
    }
    return <form onSubmit={this.demo_config.bind(this)}>
      <Column.Group>
        <Column size={6}>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Config</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('config')}
                    defaultValue={JSON.stringify(config, null, 2)}
                    rows={12}
                    name="config"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          {
            updated_config ?
              <Notification color="success">
                Đổi cấu hình thành công.<br/>
              </Notification> :
              null
          }
          {
            errors ?
              <Notification color="danger">{errors}</Notification> :
              null
          }

          <Field kind="group" align="right">
            <Control>
              <Button color="info">Update config</Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>;
  }

  public create_user(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const payload = {
      username: data.get('username') as string,
      create: true,
      id: data.get('id') as string,
    };
    this.props.createUserRequest(payload);
  }

  public onChangeAction(event: any) {
    let form = event.target.form;
    let key = event.target.value;
    let action = _.find(actions, {key});
    if (action) {
      form.querySelector('[name=data]').value = JSON.stringify(action.data, null, 2);
    }
  }

  public render_create_user() {
    const {account, created_user, errors} = this.props;
    return <form onSubmit={this.create_user.bind(this)}>
      <Column.Group>
        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>Username</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('username')}
                    type="text"
                    placeholder="username của người dùng"
                    name="username"
                    maxLength="128"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Custom id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('custom_id')}
                    type="text"
                    placeholder="custom id của người dùng"
                    name="id"
                    maxLength="128"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          {
            created_user ?
              <Notification color="success">
                Tạo người dùng thành công.<br/>
                user_id: {created_user.user_id}<br/>
                username: {created_user.username}<br/>
              </Notification> :
              null
          }
          {
            errors ?
              <Notification color="danger">{errors}</Notification> :
              null
          }

          <Field kind="group" align="right">
            <Control>
              <Button color="info">Create</Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>;
  }

  public demo_topup(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const {token} = localStorage;
    const session = {token};
    const payload = {
      session,
      user_id: data.get('user_id') as string,
      amount: Number(data.get('amount') as string),
    };
    this.props.demoTopupRequest(payload);
  }

  public render_demo_topup() {
    const {account, topup, selected_user, errors} = this.props;
    return <form onSubmit={this.demo_topup.bind(this)}>
      <Column.Group>
        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>User id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    readonly
                    key={this.key('user_id')}
                    type="text"
                    placeholder="id của người dùng"
                    defaultValue={selected_user && selected_user.id}
                    name="user_id"
                    maxLength="128"
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Button onClick={this.selectUser.bind(this, '/demo/demo_topup')}>Select</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>
          {selected_user && <Column.Group>
            <Column size={4}></Column>
            <Column size={8}>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Username</Label>
                </Field.Label>
                <Field.Body>
                  {selected_user.username}
                </Field.Body>
              </Field>
            </Column>
          </Column.Group>}

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Amount</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('amount')}
                    type="number"
                    placeholder="Số tiền topup"
                    name="amount"
                    maxLength="128"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          {
            topup ?
              <Notification color="success">
                Nạp tiền thành công.<br/>
                Được thêm: {topup.gems} gems
              </Notification> :
              null
          }
          {
            errors ?
              <Notification color="danger">{errors}</Notification> :
              null
          }

          <Field kind="group" align="right">
            <Control>
              <Button color="info">Topup</Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>;
  }

  public demo_action(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const payload = {
      user_id: data.get('user_id') as string,
      action: data.get('action') as string,
      data: JSON.parse(data.get('data') as string),
    };
    this.props.demoActionRequest(payload);
  }

  public render_demo_action() {
    const {account, action, selected_user, errors} = this.props;
    return <form onSubmit={this.demo_action.bind(this)}>
      <Column.Group>
        <Column size={8}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>User id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    readonly
                    key={this.key('user_id')}
                    type="text"
                    placeholder="id của người dùng"
                    defaultValue={selected_user && selected_user.id}
                    name="user_id"
                    maxLength="128"
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Button onClick={this.selectUser.bind(this, '/demo/demo_action')}>Select</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>
          {selected_user && <Column.Group>
            <Column size={4}></Column>
            <Column size={8}>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Username</Label>
                </Field.Label>
                <Field.Body>
                  {selected_user.username}
                </Field.Body>
              </Field>
            </Column>
          </Column.Group>}

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Action</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Select.Container>
                    <Select
                      key={this.key('action')}
                      name="action"
                      onChange={this.onChangeAction.bind(this)}
                    >
                      <Select.Option value=""></Select.Option>
                      {actions.map(action => (
                        <Select.Option key={action.key} value={action.key}>{action.text}</Select.Option>
                      ))}
                    </Select>
                  </Select.Container>
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Data</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Textarea
                    key={this.key('data')}
                    placeholder="Tên action"
                    rows={6}
                    name="data"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          {
            action ?
              <Notification color="success">
                Ghi nhận action thành công.<br/>
              </Notification> :
              null
          }
          {
            errors ?
              <Notification color="danger">{errors}</Notification> :
              null
          }

          <Field kind="group" align="right">
            <Control>
              <Button color="info">Action</Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>;
  }

  public demo_consume_gems(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const payload = {
      user_id: data.get('user_id') as string,
      gems: Number(data.get('gems') as string),
    };
    this.props.demoConsumeGemsRequest(payload);
  }

  public render_demo_consume_gems() {
    const {account, consume_gems, selected_user, errors} = this.props;
    return <form onSubmit={this.demo_consume_gems.bind(this)}>
      <Column.Group>
        <Column size={6}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>User id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    readonly
                    key={this.key('user_id')}
                    type="text"
                    placeholder="id của người dùng"
                    defaultValue={selected_user && selected_user.id}
                    name="user_id"
                    maxLength="128"
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Button onClick={this.selectUser.bind(this, '/demo/demo_consume_gems')}>Select</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>
          {selected_user && <Column.Group>
            <Column size={4}></Column>
            <Column size={8}>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Username</Label>
                </Field.Label>
                <Field.Body>
                  {selected_user.username}
                </Field.Body>
              </Field>
            </Column>
          </Column.Group>}

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Gems</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('gems')}
                    type="number"
                    placeholder="Số gems chuyển thành tiền"
                    name="gems"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          {
            consume_gems ?
              <Notification color="success">
                Đổi tiền thành công.<br/>
                Đã đổi: {consume_gems.gems} gems
              </Notification> :
              null
          }
          {
            errors ?
              <Notification color="danger">{errors}</Notification> :
              null
          }

          <Field kind="group" align="right">
            <Control>
              <Button color="info">Consume gems</Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>;
  }

  public demo_exchange_gift(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const payload = {
      user_id: data.get('user_id') as string,
      gift_id: data.get('gift_id') as string,
      type: data.get('type') as string,
      count: Number(data.get('count') as string),
    };
    this.props.demoExchangeGiftRequest(payload);
  }

  public selectUser(back: string, event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { history } = this.props;
    history.push('/users?back=' + back);
  }

  public selectGift(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { history } = this.props;
    history.push('/gifts?back=/demo/demo_exchange_gift');
  }

  public render_demo_exchange_gift() {
    const {account, exchange_gift, selected_gift, selected_user, errors} = this.props;
    console.log(selected_user);
    return <form onSubmit={this.demo_exchange_gift.bind(this)}>
      <Column.Group>
        <Column size={8}>
          <Field horizontal>
            <Field.Label size="normal">
              <Label>User id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    readonly
                    key={this.key('user_id')}
                    type="text"
                    placeholder="id của người dùng"
                    defaultValue={selected_user && selected_user.id}
                    name="user_id"
                    maxLength="128"
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Button onClick={this.selectUser.bind(this, '/demo/demo_exchange_gift')}>Select</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>
          {selected_user && <Column.Group>
            <Column size={4}></Column>
            <Column size={8}>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Username</Label>
                </Field.Label>
                <Field.Body>
                  {selected_user.username}
                </Field.Body>
              </Field>
            </Column>
          </Column.Group>}

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Item id</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    readonly
                    key={this.key('gift_id')}
                    type="text"
                    placeholder="id của item"
                    defaultValue={selected_gift && selected_gift.id}
                    name="gift_id"
                    maxLength="128"
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Button onClick={this.selectGift.bind(this)}>Select</Button>
                </Control>
              </Field>
            </Field.Body>
          </Field>
          {selected_gift && <Column.Group>
            <Column size={4}></Column>
            <Column size={8}>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Name</Label>
                </Field.Label>
                <Field.Body>
                  {selected_gift.name}
                </Field.Body>
              </Field>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Description</Label>
                </Field.Label>
                <Field.Body>
                  {selected_gift.description}
                </Field.Body>
              </Field>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Image</Label>
                </Field.Label>
                <Field.Body>
                  <img src={selected_gift.image_url} width="80" />
                </Field.Body>
              </Field>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Gems</Label>
                </Field.Label>
                <Field.Body>
                  {selected_gift.gems}
                </Field.Body>
              </Field>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Coins</Label>
                </Field.Label>
                <Field.Body>
                  {selected_gift.coins}
                </Field.Body>
              </Field>
              <Field horizontal>
                <Field.Label size="normal">
                  <Label>Quantity</Label>
                </Field.Label>
                <Field.Body>
                  {selected_gift.quantity}
                </Field.Body>
              </Field>
            </Column>
          </Column.Group>}

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Action</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Select.Container>
                    <Select key={this.key('type')} name="type">
                      <Select.Option value="gems">Gems</Select.Option>
                      <Select.Option value="coins">Coins</Select.Option>
                    </Select>
                  </Select.Container>
                </Control>
              </Field>
            </Field.Body>
          </Field>

          <Field horizontal>
            <Field.Label size="normal">
              <Label>Count</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <Control>
                  <Input
                    key={this.key('count')}
                    type="number"
                    placeholder="Số item"
                    defaultValue="1"
                    name="count"
                  />
                </Control>
              </Field>
            </Field.Body>
          </Field>

          {
            exchange_gift ?
              <Notification color="success">
                Đổi item thành công.<br/>
              </Notification> :
              null
          }
          {
            errors ?
              <Notification color="danger">{errors}</Notification> :
              null
          }

          <Field kind="group" align="right">
            <Control>
              <Button color="info">Exchange item</Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>;
  }

  public render() {
    const {account, match} = this.props;
    const {tab} = this.state;
    console.log('tab', tab);
    return <Generic id="users_details">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="demo"/>

          <Column>
            <Level>
              <Level.Item align="left">
                    {/*
                <Level.Item>
                  <Breadcrumb>
                    <Breadcrumb.Item as="span"><Link to="/users">Users</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{account.account.user.id}</Breadcrumb.Item>
                  </Breadcrumb>
                </Level.Item>
                    */}
              </Level.Item>
              <Level.Item align="right">
                {/*
                <Level.Item>
                  <Button onClick={exportRequest.bind(this, match.params)}>
                    <Icon>
                      <FontAwesomeIcon icon="file-export"/>
                    </Icon>
                    <span>Export</span>
                  </Button>
                </Level.Item>
                  account.disable_time ?
                    <Level.Item>
                      <Button onClick={this.unban.bind(this)}>
                        <Icon>
                          <FontAwesomeIcon icon="ban"/>
                        </Icon>
                        <span>Unban</span>
                      </Button>
                    </Level.Item> :
                    <Level.Item>
                      <Button onClick={this.ban.bind(this)}>
                        <Icon>
                          <FontAwesomeIcon icon="ban"/>
                        </Icon>
                        <span>Ban</span>
                      </Button>
                    </Level.Item>
                <Level.Item>
                  <Button onClick={this.remove.bind(this, false)}>
                    <Icon>
                      <FontAwesomeIcon icon="trash"/>
                    </Icon>
                    <span>Delete</span>
                  </Button>
                </Level.Item>
                <Level.Item>
                  <Button onClick={this.remove.bind(this, true)}>
                    <Icon>
                      <FontAwesomeIcon icon="trash"/>
                    </Icon>
                    <span>Recorded Delete</span>
                  </Button>
                </Level.Item>
                */}
              </Level.Item>
            </Level>

            <Tab.Group>
              <Tab
                active={tab === 'demo_config'}
                onClick={this.switch_tab.bind(this, 'demo_config')}
              >Config</Tab>
              <Tab
                active={tab === 'create_user'}
                onClick={this.switch_tab.bind(this, 'create_user')}
              >Create user</Tab>
              <Tab
                active={tab === 'demo_topup'}
                onClick={this.switch_tab.bind(this, 'demo_topup')}
              >Topup</Tab>
              <Tab
                active={tab === 'demo_action'}
                onClick={this.switch_tab.bind(this, 'demo_action')}
              >Action</Tab>
              <Tab
                active={tab === 'demo_consume_gems'}
                onClick={this.switch_tab.bind(this, 'demo_consume_gems')}
              >Consume gems</Tab>
              <Tab
                active={tab === 'demo_exchange_gift'}
                onClick={this.switch_tab.bind(this, 'demo_exchange_gift')}
              >Exchange item</Tab>
            </Tab.Group>

            {this[`render_${tab}`]()}
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({demo, user_details, gift, user}: ApplicationState) => ({
  loading: demo.loading,
  errors: demo.errors,
  created_user: demo.created_user,
  topup: demo.topup,
  action: demo.action,
  consume_gems: demo.consume_gems, 
  exchange_gift: demo.exchange_gift, 
  config: demo.config, 
  updated_config: demo.updated_config, 
  data: user_details.data,
  account: user_details.account,
  selected_gift: gift.selected,
  selected_user: user.selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createUserRequest: (data: CreateUserRequest) => dispatch(
    demoActions.createUserRequest(data)
  ),
  demoTopupRequest: (data: DemoTopupRequest) => dispatch(
    demoActions.demoTopupRequest(data)
  ),
  demoActionRequest: (data: DemoActionRequest) => dispatch(
    demoActions.demoActionRequest(data)
  ),
  demoConsumeGemsRequest: (data: DemoConsumeGemsRequest) => dispatch(
    demoActions.demoConsumeGemsRequest(data)
  ),
  demoExchangeGiftRequest: (data: DemoExchangeGiftRequest) => dispatch(
    demoActions.demoExchangeGiftRequest(data)
  ),
  demoGetConfigRequest: (data: DemoGetConfigRequest) => dispatch(
    demoActions.demoGetConfigRequest(data)
  ),
  demoSetConfigRequest: (data: DemoSetConfigRequest) => dispatch(
    demoActions.demoSetConfigRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Demo);
