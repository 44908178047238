import React from 'react';

import {Dropdown, Button, Column, Control, Field, Generic, Icon, Input, Level, Section, Table, Title, Select} from 'rbx';

import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import _ from 'lodash'

import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import './grid.css';
import { default_key } from '../../store/wheel_gifts/sagas'
import moment from 'moment'

import {WheelGiftObject} from '../../store/wheel_gifts/types'
import {GameObject} from '../../store/games/types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Cell = {
  v: number
}

type GiftConfig = {
  id?: number
  game_id: string
  voucher_id: string
  segment: number
  content: Record<string, number[]>
}

const WheelGiftsConfig = () => {
  const [game, setGame] = React.useState('')
  const [gameConfig, setGameConfig] = React.useState<any>({})
  const [mode, setMode] = React.useState(0)

  const [games, setGames] = React.useState<GameObject[]>([])
  const [change, setChange] = React.useState(false)
  const gameItems= React.useRef<WheelGiftObject[]>()
  const gameItemConfigs = React.useRef<Record<string, GiftConfig>>()

  const loadGames = async () => {
    try {
      const res = await window.nakama_client.rpcGet('get_game', null, default_key, { offset: 0, limit: 100 });
      console.log('[loadGames]', res)
      if (!res.error && res.payload) {
        setGames(res.payload.games)
      }
    } catch (e) {
      console.log('[loadGames] error', e)
    }
  }

  React.useEffect(() => {
    loadGames()
  }, [])

  const loadGameItems = async () => {
    try {
      if (!game) return []
      const res = await window.nakama_client.rpcGet('get_wheel_gift', null, default_key, { game });
      console.log('[loadGameItems]', res)
      if (!res.error && res.payload) {
        if (Array.isArray(res.payload.gifts)) {
          gameItems.current = res.payload.gifts
        } else {
          gameItems.current = [res.payload.gifts]
        }
        return gameItems.current!
      }
    } catch (e) {
      console.log('[loadGameItems] error', e)
    }
    return []
  }

  const loadGameItemConfigs = async () => {
    try {
      if (!game) return
      const res = await window.nakama_client.rpcGet('get_game_item_config', null, default_key, { game });
      console.log('[loadGameItemConfigs]', res)
      if (!res.error) {
        let arr = res.payload || []
        if (!Array.isArray(arr)) arr = [arr]

        const _gameItemConfigs = _.mapKeys(arr, it => `${it.voucher_id}-${it.segment}`)
        gameItemConfigs.current = _gameItemConfigs
        return _gameItemConfigs
      }
    } catch (e) {
      console.log('[loadGameItemConfigs] error', e)
    }
  }

  const [fromDate, setFromDate] = React.useState<Date>()
  const [toDate, setToDate] = React.useState<Date>()
  const [dateArray, setDateArray] = React.useState<string[]>()
  const [grid, setGrid] = React.useState<any[][]>()
  const [segments, setSegments] = React.useState<number[]>([0])

  const loadGameData = async () => {
    const gameItems = await loadGameItems()
    const gameItemConfigs = (await loadGameItemConfigs()) as Record<string, GiftConfig>

    for (let item of gameItems) {
      const useParams = JSON.parse(item.use_params || '{}')
      if (useParams.type == 'none') {
        const config = gameItemConfigs[item.voucher_id]
        if (config) {
          for (let cell of Object.values(config.content)) {
            cell[1] = 0
          }
        }
      }
    }

    const gameRecord = _.find(games, it => it.id == game)
    if (!gameRecord || !gameRecord.from_date || !gameRecord.to_date) return
    const fromDate = new Date(gameRecord.from_date * 1000)
    const toDate = new Date(gameRecord.to_date * 1000)
    setFromDate(fromDate)
    setToDate(toDate)

    var date = moment(fromDate)
    var stopDate = moment(toDate)
    var count = 0
    var dateArray = [] as string[]
    while (date < stopDate && count < 60) {
      dateArray.push(date.format('YYYY-MM-DD'))
      date.add(1, 'days')
    }

    var data = _.times(
      (gameItems.length * segments.length) + 2,
      () => _.times(
        dateArray.length + 3,
        () => ({ v: 0 })
      ),
    )

    setDateArray(dateArray)
    setChange(false)
    setGrid(data)
  }

  const saveGameItemConfigs = async () => {
    try {
      console.log('[saveGameItemConfigs] data', JSON.stringify(gameItemConfigs.current))
      if (!gameItemConfigs.current) return
      for (let row of Object.values(gameItemConfigs.current)) {
        if ('undefined' in row) delete row['undefined']
        if (_.isEmpty(row)) continue
        const res = await window.nakama_client.rpcGet(
          'set_game_item_config',
          null,
          default_key, 
          { [row.voucher_id]: row });
        console.log('[saveGameItemConfigs]', res)
      }
      alert('save success')
      setChange(false)
    } catch (e) {
      console.log('[saveGameItemConfigs]', e)
    }
  }

  React.useEffect(() => {
    if (!game) return
    setGrid(undefined)
    gameItems.current = undefined
    gameItemConfigs.current = undefined
    loadGameData()
  }, [game])


  //const {data, selected} = this.props;
  //const selected_id = selected && selected.id;

  //const max_range_obj = _.maxBy(data.gifts, it => Number(it.rate_to))
  //const max_range = max_range_obj ? Number(max_range_obj.rate_to) : 0
  //

  const updateGiftedCount = async (date: string) => {
    try {
      if (change) {
        alert('Dữ liệu đã bị thay đổi, bạn cần lưu dữ liệu trước khi update')
        return
      }
      if (!confirm(`Bạn có chắc chắn cập nhật số liệu ngày ${moment(date).add(-1, 'day').format('YYYY-MM-DD')}`)) {
        return
      }
      const res = await window.nakama_client.rpcGet(
        'update_gifted_count',
        null,
        default_key, 
        { game_id: game, date });
      if (res.payload && res.payload.ok === 0) {
        alert('Cập nhật dữ liệu không thành công: ' + res.payload.msg)
        return
      }
      console.log('[updateGiftedCount]', res)
      alert('Cập nhật thành công')
      loadGameData()
    } catch (e) {
      alert('Cập nhật dữ liệu không thành công: ' + (e as Error).message)
      console.log('[updateGiftedCount]', e)
    }
  }

  const setGiftCount = async (date: string, accum: boolean) => {
    try {
      if (!gameItemConfigs.current || !gameItems.current) return
      if (change) {
        alert('Dữ liệu đã bị thay đổi, bạn cần lưu dữ liệu trước khi set số lượng quà')
        return
      }
      if (!confirm(`Bạn có chắc chắn sử dụng số quà của ngày ${moment(date).format('YYYY-MM-DD')} cho hiện tại không?`)) {
        return
      }
      const _rows = _.sortBy(Object.values(gameItemConfigs.current), it => it.segment)
      const rows = _rows.slice(1, _rows.length)
      const first = _rows[0]
      console.log('dayBefore')
      var missData = false
      if (rows.length < gameItems.current.length) {
        console.log('length not match', rows.length, gameItems.current.length)
        alert('Bạn chưa nhập đủ dữ liệu cho tất cả các item')
        return
      }
      var count: any[] = []
      var itemCount = gameItems.current.length
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]
        if (!row.content) continue
        const cell = row.content[date]
        if (!cell) {
          missData = true
          break
        }
        if (typeof cell[1] == 'undefined' && accum) {
          missData = true
          break
        }
        //const segment = Math.floor(i / itemCount)
        const segment = row.segment
        count.push({ voucher_id: row.voucher_id, quantity: cell[0] + (accum ? cell[1] : 0), segment })
      }
      const firstCell = first.content[date]
      if (!firstCell) {
        missData = true
      }
      if (typeof firstCell[1] == 'undefined' && accum) {
        missData = true
      }
      if (missData) {
        alert('Bạn chưa tính dữ liệu của ngày hôm qua hoặc nhập dữ liệu của hôm nay')
        return
      }
      const countByVoucher = _.groupBy(count, it => it.voucher_id)
      let countTransform = {} as Record<string, number[]>
      let segmentToIndex = {} as Record<number, number>
      for (let i = 0; i < segments.length; i++) segmentToIndex[segments[i]] = i
      for (let voucherId in countByVoucher) {
        let group = countByVoucher[voucherId]
        let ar = [] as number[]
        for (let item of group) {
          ar[segmentToIndex[item.segment] || 0] = item.quantity
        }
        countTransform[voucherId] = ar
      }
      const res = await window.nakama_client.rpcGet(
        'set_game_item_count_with_segment',
        null,
        default_key, 
        { game_id: game, date, segments,  count: countTransform, no_gift_count: firstCell[0] });
      alert('set count success')
      loadGameData()
      console.log('[setGiftCount]', res)
    } catch (e) {
      alert('set count fail: ' + (e as Error).message)
      console.log('[setGiftCount] error', e)
    }
  }

  return (
    <Generic id="gifts">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="wheel_gifts/config"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Title subtitle size={5}>
                  </Title>
                </Level.Item>

                <Level.Item>
                  <Field kind="addons">
                    <Control>
                      {/*<Input id="filter" type="text" placeholder="Find a gift"  />*/}
                      {!_.isEmpty(games) && <Select
                        name="game"
                        onChange={(e: any) => {
                          setGame(e.target.value)
                          const game = _.find(games, it => it.id == e.target.value)
                          if (game) {
                            const gameConfig = JSON.parse(game.config || '{}')
                            setGameConfig(gameConfig)
                            const segments = gameConfig.segments || [0]
                            setSegments(segments)
                          }
                          gameItemConfigs.current = undefined
                          gameItems.current = undefined
                        }}
                      >
                      <Select.Option value={game}></Select.Option>
                        {games.map((game: any) => (
                          <Select.Option key={game.id} value={game.id}>{game.name}</Select.Option>
                        ))}
                      </Select>}
                    </Control>
                  </Field>
                  {change ? '*' : ''}
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                  <Control>
                    <Button onClick={saveGameItemConfigs} >Save</Button>
                    <Button onClick={() => setMode(1 - mode)} >{mode == 0 ? 'Số item dự kiến' : 'Số item còn dư'}</Button>
                  </Control>
                </Level.Item>
              </Level.Item>
            </Level>

            {grid && <ReactDataSheet
              data={grid as any}
              cellRenderer={({row, col, cell, children, selected, updated, editing, attributesRenderer, ...props}) => {
                if (row == 0) {
                  if (col < 3) {
                    return <th {...props as any}>{children}</th>
                  } else {
                    return <th {...props as any}>
                      {children}<br/>
                      <Dropdown>
                        <Dropdown.Trigger>
                          <Button>
                            <span>Menu</span>
                            <Icon>
                              <FontAwesomeIcon icon="angle-down"/>
                            </Icon>
                          </Button>
                        </Dropdown.Trigger>
                        <Dropdown.Menu>
                          <Dropdown.Content>
                            <Dropdown.Item onClick={() => dateArray && updateGiftedCount(dateArray[col - 3])}>
                              <Icon>
                                <FontAwesomeIcon icon="file-csv" />
                              </Icon>
                              <span>Cập nhật số liệu quà đã phát và còn dư của ngày hôm trước</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => dateArray && setGiftCount(dateArray[col - 3], true)}>
                              <Icon>
                                <FontAwesomeIcon icon="file-csv" />
                              </Icon>
                              <span>Sử dụng số liệu quà của ngày này (Cộng dồn cả quà dư của hôm trước)</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => dateArray && setGiftCount(dateArray[col - 3], false)}>
                              <Icon>
                                <FontAwesomeIcon icon="file-csv" />
                              </Icon>
                              <span>Sử dụng số liệu quà của ngày này (Không cộng dồn số dư)</span>
                            </Dropdown.Item>
                          </Dropdown.Content>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/*
                        *<Button className="w-100" onClick={() => dateArray && updateGiftedCount(dateArray[col - 2])}>Update</Button><br/>
                        *<Button className="w-100" onClick={() => dateArray && setGiftCount(dateArray[col - 2])}>Set</Button><br/>
                        */}
                    </th>
                  }
                }
                if (!gameItems.current) return <td></td>
                const segmentIdx = Math.floor((row - 2) / gameItems.current.length)
                const segment = segmentIdx < 0 ? -1 : segments[segmentIdx]
                const itemIdx = (row - 2) % gameItems.current.length
                const item = itemIdx < 0
                  ? { voucher_id: '0', name: 'Không có quà' }
                  : gameItems.current[itemIdx]
                if (col == 0) return <td {...props as any}>{children}</td>
                if (col == 1) return <td {...props as any}>
                  <span className="value-viewer">{0 /*item.quantity*/}</span>
                  <div className="extra">
                    <span></span>
                    <span>{item.name}</span>
                  </div>
                </td>
                if (col == 2) return <td {...props as any}>{children}</td>
                if (editing) {
                  return <td {...props as any}>{children}</td>
                }

                if (gameItemConfigs.current && dateArray) {
                  const key = `${item.voucher_id}-${segment}`
                  const itemConfig = gameItemConfigs.current[key]
                  if (itemConfig && itemConfig.content) {
                    const myCell = itemConfig.content[dateArray[col-3]]
                    if (myCell) return <td {...props as any}>
                      <span className="value-viewer">{myCell[0]}</span>
                      {typeof myCell[1] != 'undefined' && <div className="extra">
                        <span>{myCell[1]}</span>
                        <span>{myCell[1] + myCell[0]}</span>
                      </div>}
                    </td>
                  }
                }
                return <td {...props as any}>{children}</td>
              }}
              valueRenderer={(cell, row, col) => {
                if (!gameItems.current || !dateArray) return ''
                if (row == 0) {
                  if (col == 0) return 'voucher_id'
                  if (col == 1) return 'name'
                  if (col == 2) return 'segment'
                  return dateArray[col-3].substr(5)
                }
                const segmentIdx = Math.floor((row - 2) / gameItems.current.length)
                const itemIdx = (row - 2) % gameItems.current.length
                const item = itemIdx < 0
                  ? { voucher_id: '0', name: 'Không có quà' }
                  : gameItems.current[itemIdx]
                if (!item) return ''
                if (col == 0) return item.voucher_id
                if (col == 1) return item.name
                if (col == 2) return segmentIdx < 0 ? -1 : segments[segmentIdx]

                if (gameItemConfigs.current && dateArray) {
                  const itemConfig = gameItemConfigs.current[item.voucher_id]
                  if (itemConfig && itemConfig.content) {
                    const myCell = itemConfig.content[dateArray[col-3]]
                    if (myCell) {
                      return mode == 0 ? myCell[0] : myCell[1]
                    }
                  }
                }
                return ''
              }}
              attributesRenderer={(cell: any, row: number, col: number) => {
                if (col == 0) return { width: 150 }
                if (col == 1) return { width: 150 }
                return {}
              }}
              onCellsChanged={changes => {
                if (!gameItemConfigs.current || !gameItems.current || !dateArray) return
                var change = false
                for (let {cell, row, col, value} of changes) {
                  if (col < 3) continue
                  if (row == 0) continue
                  change = true
                  break
                }
                if (!change) return
                const _grid = grid.map(row => row);

                changes.forEach(({ cell, row, col, value }: any) => {
                  if (!gameItems.current || !gameItemConfigs.current) return
                  _grid[row][col] = { v: cell.v + 1 };
                  var segmentIdx  = Math.floor((row - 2) / gameItems.current.length)
                  var segment = segmentIdx < 0 ? -1 : segments[segmentIdx]
                  var itemIdx = (row - 2) % gameItems.current.length
                  var item = itemIdx < 0
                      ? { voucher_id: '0', game_id: game, name: 'Không có quà' }
                      : gameItems.current![itemIdx]
                  const key = `${item.voucher_id}-${segment}`
                  var itemConfig = gameItemConfigs.current[key]
                  if (!itemConfig) {
                    itemConfig = {
                      game_id: item.game_id,
                      voucher_id: item.voucher_id,
                      segment,
                      content: {}
                    }
                    gameItemConfigs.current[key] = itemConfig
                  }
                  var date = dateArray[col - 3]
                  var _cell = itemConfig.content[date]
                  if (!_cell) {
                    _cell = [0]
                    itemConfig.content[date] = _cell
                  }
                  _cell[0] = Number(value)

                });
                setChange(true)
                setGrid(_grid)
              }}
            />}
            
          </Column>
        </Column.Group>
      </Section>
    </Generic>
  );
}

export default WheelGiftsConfig;
