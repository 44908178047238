export interface GameObjectRequest {
  id?: string,
};

export interface GameObject {
  id: string,
  name?: string,
  logo?: string,
  cover?: string,
  description?: string,
  groupid?: number,
  config?: string,
  status?: number,
  type?: string,
  url?: string,
  from_date?: number,
  to_date?: number,
  total_items?: number,
  average_game_for_one_win?: number
  tester?: string
};

export interface GamesObjectRequest {
  filter?: string,
};

export interface GamesObject {
  games: GameObject[],
  total_count: number,
};

export enum GameActionTypes {
  SELECT = '@@game/SELECT',
  FETCH_MANY_REQUEST = '@@game/FETCH_MANY_REQUEST',
  FETCH_MANY_SUCCESS = '@@game/FETCH_MANY_SUCCESS',
  FETCH_MANY_ERROR = '@@game/FETCH_MANY_ERROR',
  FETCH_REQUEST = '@@game/FETCH_REQUEST',
  FETCH_SUCCESS = '@@game/FETCH_SUCCESS',
  FETCH_ERROR = '@@game/FETCH_ERROR',
  UPDATE_REQUEST = '@@game/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@@game/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@game/UPDATE_ERROR',
};

export interface GamesState {
  readonly loading: boolean,
  readonly data: GamesObject,
  readonly errors?: string,
  selected?: GameObject,
};

export interface GameState {
  readonly loading: boolean,
  readonly updated: boolean,
  readonly errors?: string
  readonly data?: any,
};
