import {AnyAction} from 'redux';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {GiftActionTypes} from './types';
import {
  giftFetchManySuccess,
  giftFetchManyError,
  giftFetchSuccess,
  giftFetchError,
  giftUpdateSuccess,
  giftUpdateError,
} from './actions';
import { getErrorFromLua } from '../../utils/error'

const default_key = process.env.REACT_APP_DEFAULTKEY || 'defaultkey_http_123'

function* handleFetchMany({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_gift', null, default_key, data);
    if(res.error) {
      yield put(giftFetchManyError(res.error));
    } else {
      yield put(giftFetchManySuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(giftFetchManyError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(giftFetchManyError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(giftFetchManyError('An unknown error occured.'));
    }
  }
}

function* handleFetch({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_gift', null, default_key, data);
    if(res.error) {
      yield put(giftFetchError(res.error));
    } else {
      yield put(giftFetchSuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (err.status == 404) {
      window.location.href = '/gifts';
    } else if(err.json) {
      const json = yield err.json();
      yield put(giftFetchError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(giftFetchError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(giftFetchError('An unknown error occured.'));
    }
  }
}

function* handleUpdate({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('set_gift', null, default_key, data);
    if(res.error) {
      yield put(giftUpdateError(res.error));
    } else {
      yield put(giftUpdateError(''));
      yield put(giftUpdateSuccess());
      yield handleFetch({type: '@@gift/FETCH_REQUEST', payload: data});
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(giftUpdateError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(giftUpdateError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(giftUpdateError('An unknown error occured.'));
    }
  }
}

function* watchFetchMany() {
  yield takeEvery(GiftActionTypes.FETCH_MANY_REQUEST, handleFetchMany);
}

function* watchFetch() {
  yield takeEvery(GiftActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchUpdate() {
  yield takeEvery(GiftActionTypes.UPDATE_REQUEST, handleUpdate);
}

export function* giftSaga()
{
  yield all([
    fork(watchFetchMany),
    fork(watchFetch),
    fork(watchUpdate),
  ]);
}
