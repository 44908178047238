import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import queryString from 'query-string';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {ApplicationState, ConnectedReduxProps} from '../../store';
import * as gameActions from '../../store/games/actions';
import {GameObject, GameObjectRequest, GamesObject, GamesObjectRequest} from '../../store/games/types';
import {Button, Column, Control, Field, Generic, Icon, Input, Level, Section, Table, Title} from 'rbx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import moment from 'moment';

interface PropsFromState {
  loading: boolean,
  errors: string | undefined,
  data: GamesObject,
}

interface PropsFromDispatch {
  fetchManyRequest: typeof gameActions.gameFetchManyRequest,
}

type Props = RouteComponentProps & PropsFromState & PropsFromDispatch & ConnectedReduxProps;

type State = {
  filter: string,
};

class Games extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {filter: ''};
  }

  public componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.filter) {
      (document.getElementById('filter') as HTMLInputElement).value = query.filter as string;
    }
    this.setState({
      filter: query.filter as string || '',
    });
    this.props.fetchManyRequest({
      filter: query.filter as string || '',
    });
  }

  public all() {
    this.props.fetchManyRequest({
      filter: this.state.filter,
    });
  }

  public filter() {
    const {history} = this.props;
    const filter = (document.getElementById('filter') as HTMLInputElement).value;
    history.push(`/games?filter=${filter}`);
    this.setState({filter});
    this.props.fetchManyRequest({filter});
  }

  public details(id: string) {
    const {history} = this.props;
    history.push(`/games/${id}`);
  }

  public add() {
    const {history} = this.props;
    history.push(`/games/add`);
  }

  public render() {
    const {data} = this.props;

    return <Generic id="games">
      <Header/>
      <Section>
        <Column.Group>
          <Sidebar active="games"/>

          <Column>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <Title subtitle size={5}>
                    ~<strong>{data.total_count}</strong> items
                  </Title>
                </Level.Item>

                <Level.Item>
                  <Field kind="addons">
                    <Control>
                      <Input id="filter" type="text" placeholder="Find a game"/>
                    </Control>
                    <Control>
                      <Button onClick={this.filter.bind(this)}>Lookup</Button>
                    </Control>
                  </Field>
                </Level.Item>
              </Level.Item>
              <Level.Item align="right">
                <Level.Item>
                  <Control>
                    <Button onClick={this.add.bind(this)}>Create</Button>
                  </Control>
                </Level.Item>
              </Level.Item>
            </Level>

            <Table fullwidth striped hoverable>
              <Table.Head>
                <Table.Row>
                  <Table.Heading style={{width: '18%'}}>ID</Table.Heading>
                  <Table.Heading style={{width: '17.5%'}}>Name</Table.Heading>
                  <Table.Heading style={{width: '17.5%'}}>Description</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Icon</Table.Heading>
                  <Table.Heading style={{width: '8'}}>Cover</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>Status</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>From date</Table.Heading>
                  <Table.Heading style={{width: '8%'}}>To date</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {
                  data.games && data.games.length && data.games.map((game, key) =>
                    <Table.Row
                      key={`cell_${key}`}
                      onClick={this.details.bind(this, game.id)}
                    >
                      <Table.Cell>{game.id}</Table.Cell>
                      <Table.Cell>{game.name}</Table.Cell>
                      <Table.Cell>{game.description}</Table.Cell>
                      <Table.Cell>{game.logo &&
                        <img src={game.logo} style={{maxWidth: 80, maxHeight: 80}} />}</Table.Cell>
                      <Table.Cell>{game.cover &&
                        <img src={game.cover} style={{maxWidth: 80, maxHeight: 80}} />}</Table.Cell>
                      <Table.Cell>{game.status}</Table.Cell>
                      <Table.Cell>{game.from_date && moment(game.from_date * 1000).format('DD/MM/YYYY')}</Table.Cell>
                      <Table.Cell>{game.to_date && moment(game.to_date * 1000).format('DD/MM/YYYY')}</Table.Cell>
                    </Table.Row>
                  )
                }
              </Table.Body>
            </Table>
          </Column>
        </Column.Group>
      </Section>
    </Generic>;
  }
}

const mapStateToProps = ({game}: ApplicationState) => ({
  loading: game.loading,
  errors: game.errors,
  data: game.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchManyRequest: (data: GamesObjectRequest) => dispatch(
    gameActions.gameFetchManyRequest(data)
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Games);
