import {AnyAction} from 'redux';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {UserGiftActionTypes} from './types';
import {
  userGiftFetchManySuccess,
  userGiftFetchManyError,
  userGiftFetchSuccess,
  userGiftFetchError,
  userGiftUpdateSuccess,
  userGiftUpdateError,
} from './actions';
import { getErrorFromLua } from '../../utils/error'

const default_key = process.env.REACT_APP_DEFAULTKEY || 'defaultkey_http_123'

function* handleFetchMany({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_user_gift', null, default_key, data);
    if (res.error) {
      yield put(userGiftFetchManyError(res.error));
    } else {
      yield put(userGiftFetchManySuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(userGiftFetchManyError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(userGiftFetchManyError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(userGiftFetchManyError('An unknown error occured.'));
    }
  }
}

function* handleFetch({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('get_wheel_gift', null, default_key, data);
    if(res.error) {
      yield put(userGiftFetchError(res.error));
    } else {
      yield put(userGiftFetchSuccess(res));
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (err.status == 404) {
      window.location.href = '/wheel_gifts';
    } else if(err.json) {
      const json = yield err.json();
      yield put(userGiftFetchError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(userGiftFetchError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(userGiftFetchError('An unknown error occured.'));
    }
  }
}

function* handleUpdate({payload: data}: AnyAction) {
  try {
    const res = yield window.nakama_client.rpcGet('set_wheel_gift', null, default_key, data);
    if(res.error) {
      yield put(userGiftUpdateError(res.error));
    } else {
      yield put(userGiftUpdateError(''));
      yield put(userGiftUpdateSuccess());
      yield handleFetch({type: '@@wheel_gift/FETCH_REQUEST', payload: data});
    }
  } catch(err) {
    console.error(err);
    if(err.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if(err.json) {
      const json = yield err.json();
      yield put(userGiftUpdateError(getErrorFromLua(json)));
    } else if(err instanceof Error) {
      yield put(userGiftUpdateError(err.stack!));
      localStorage.clear();
      window.location.href = '/';
    } else {
      yield put(userGiftUpdateError('An unknown error occured.'));
    }
  }
}

function* watchFetchMany() {
  yield takeEvery(UserGiftActionTypes.FETCH_MANY_REQUEST, handleFetchMany);
}

function* watchFetch() {
  yield takeEvery(UserGiftActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchUpdate() {
  yield takeEvery(UserGiftActionTypes.UPDATE_REQUEST, handleUpdate);
}

export function* userGiftSaga()
{
  yield all([
    fork(watchFetchMany),
    fork(watchFetch),
    fork(watchUpdate),
  ]);
}
