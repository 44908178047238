import _ from 'lodash';

/*
"{"code":13,"error":{"Type":2,"Object":"/nakama/data/modules/gift.lua:75: Not enough item","StackTrace":"stack traceback:\n\t[G]: in function 'error'\n\t/nakama/data/modules/gift.lua:75: in main chunk\n\t[G]: ?","Cause":null},"message":"/nakama/data/modules/gift.lua:75: Not enough item\nstack traceback:\n\t[G]: in function 'error'\n\t/nakama/data/modules/gift.lua:75: in main chunk\n\t[G]: ?"}"
*/
export const getErrorFromLua = (response: any) => {
  const message =
    _.get(response, 'error.Object') ||
    _.get(response, 'message')
    response.toString();

  const match = message.match(/.*lua:\d+:\s+(.*)/);
  if (match) {
    return match[1];
  } else {
    return message;
  }
}
