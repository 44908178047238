export interface CreateUserRequest {
  id: string,
  username: string,
};

export interface DemoTopupRequest {
  user_id: string,
  amount: number,
};

export interface DemoActionRequest {
  user_id: string,
  action: string,
  data: any,
};

export interface DemoConsumeGemsRequest {
  user_id: string,
  gems: number,
};

export interface DemoGetConfigRequest {
};

export interface DemoSetConfigRequest {
  config: any,
};

export interface DemoExchangeGiftRequest {
  user_id: string,
  gift_id: string,
  type: string,
  count: number,
};

export enum DemoActionTypes {
  CREATE_USER_REQUEST = '@@demo/CREATE_REQUEST',
  CREATE_USER_SUCCESS = '@@demo/CREATE_SUCCESS',
  CREATE_USER_ERROR = '@@demo/CREATE_ERROR',
    
  DEMO_TOPUP_REQUEST = '@@demo/TOPUP_REQUEST',
  DEMO_TOPUP_SUCCESS = '@@demo/TOPUP_SUCCESS',
  DEMO_TOPUP_ERROR = '@@demo/TOPUP_ERROR',

  DEMO_ACTION_REQUEST = '@@demo/ACTION_REQUEST',
  DEMO_ACTION_SUCCESS = '@@demo/ACTION_SUCCESS',
  DEMO_ACTION_ERROR = '@@demo/ACTION_ERROR',

  DEMO_CONSUME_GEMS_REQUEST = '@@demo/CONSUME_GEMS_REQUEST',
  DEMO_CONSUME_GEMS_SUCCESS = '@@demo/CONSUME_GEMS_SUCCESS',
  DEMO_CONSUME_GEMS_ERROR = '@@demo/CONSUME_GEMS_ERROR',

  DEMO_GET_CONFIG_REQUEST = '@@demo/GET_CONFIG_REQUEST',
  DEMO_GET_CONFIG_SUCCESS = '@@demo/GET_CONFIG_SUCCESS',
  DEMO_GET_CONFIG_ERROR = '@@demo/GET_CONFIG_ERROR',

  DEMO_SET_CONFIG_REQUEST = '@@demo/SET_CONFIG_REQUEST',
  DEMO_SET_CONFIG_SUCCESS = '@@demo/SET_CONFIG_SUCCESS',
  DEMO_SET_CONFIG_ERROR = '@@demo/SET_CONFIG_ERROR',

  DEMO_EXCHANGE_GIFT_REQUEST = '@@demo/EXCHANGE_GIFT_REQUEST',
  DEMO_EXCHANGE_GIFT_SUCCESS = '@@demo/EXCHANGE_GIFT_SUCCESS',
  DEMO_EXCHANGE_GIFT_ERROR = '@@demo/EXCHANGE_GIFT_ERROR',
};

export interface DemoState {
  readonly loading: boolean,
  readonly errors?: string,
  readonly created_user?: any,
  readonly topup?: any,
  readonly action?: any,
  readonly consume_gems?: any,
  readonly config?: any,
  readonly updated_config?: any,
  readonly exchange_gift?: any,
};
